
import React, { Component } from 'react';
import Select from './common/select';
import {AddMake} from './partial/addMake';
import {AddModel} from './partial/addModel';
import {AddTrim} from './partial/addTrim';

import { HTTPService } from "../services/httpService";

const API = new HTTPService();


export default class Vehicles extends Component {

    state = {
        makes : [] ,
        selectedMake : "Make",
        selectedMakeId : "",
        models : [] ,
        selectedModel : "Model",
        selectedModelId : "",
        trims : [] ,
        selectedTrim : "Trim",
        bodies : ['Body','compact', 'crossover','roadster','coupe','sedan','SUV','wagon'] ,
        selectedBody : "Body",
        sizes: ["Size", 'S','M','L'],
        vehicleSize : "",
        errors : {},
        message : "",
        vehicle : ""
        }

    
// this will return the services offered

    async componentDidMount() {
        // populate years from current year + 1 back to 1950
//            console.log("Here's the year we are looking for :", yearSelect);
        let makesRequest = "/makes";        
        const  { data : makes }  =  await API.get(makesRequest);
        this.setState({ makes , selectedMake : "Make" });  
        };

    readMakes = async (yearSelect) => {
//            console.log("Here's the year we are looking for :", yearSelect);
            let makesRequest = "/makes/"+yearSelect;        
            const  { data : makes }  =  await API.get(makesRequest);
            this.setState({ makes , selectedMake : "Make" });  
            }

    showMake = async () => {
                this.setState({showMake : true});
    };
    
    addMake = async (make, startYear, endYear) => {
        console.log("We are adding :", make, startYear, endYear)
        let maxMakeRequest = "/maxmake";        
        const  { data : lastMake }  =  await API.get(maxMakeRequest);
        console.log ("Last Make number :", lastMake[0].last_make);
        let newMake = {
            makeId : lastMake[0].last_make + 1,
            makeName : make,
            dateCreate : startYear,
            dateUpdate : endYear
        };
        let makesAdd = "/makes";        
        const  { data : makes }  =  await API.post(makesAdd, newMake);
        this.setState({showMake : false});

    };
                

    readModels = async (makeId) => {
        console.log("Here's the make we are looking for :", makeId);
        let vehicleRequest = "0000"+makeId[0].id_car_make;
        let modelsRequest = "/models/"+vehicleRequest;        
        const  { data : models }  =  await API.get(modelsRequest);
        this.setState({ models , selectedModel : "Model" });  
        }

        showModel = async () => {
            this.setState({showModel : true});
};

    addModel = async (model, startYear, endYear, bodySize) => {
        console.log("We are adding :", this.state.selectedMake, model, startYear, endYear, bodySize)
        let maxModelRequest = "/maxmodel";        
        const  { data : lastModel }  =  await API.get(maxModelRequest);
        console.log ("Last Model number :", lastModel[0].last_model);
        let newModel = {
            modelId : lastModel[0].last_model + 1,
            makeId : this.state.selectedMakeId,
            Name : model,
            dateCreate : startYear,
            dateUpdate : endYear,
            carType : bodySize
        };
        let modelsAdd = "/models";        
        const  { data : models }  =  await API.post(modelsAdd, newModel);

        console.log ("Here's the new model number ", models);

        this.setState({showModel : false, selectedModelId : newModel.modelId});

        // add a basic trim for the new model
        this.addTrim("Other", startYear, endYear,"Default");

        
    };
    

    readTrims = async (modelId) => {
//        console.log("Here's the model we are looking for :", modelId);
        let trimRequest = modelId[0].id_car_model;
        let trimsRequest = "/trims/"+trimRequest;        
        const  { data : trims }  =  await API.get(trimsRequest);

// Also need to set up the body style drop down based on the selections here
        this.setState({ trims , selectedTrim : "Trim" });  
        }

        showTrim = async () => {
            this.setState({showTrim : true});
};

    addTrim = async (trim, startYear, endYear, bodyStyle) => {
        console.log("We are adding :",this.state.selectedMake, this.state.selectedModel ,trim, bodyStyle, startYear, endYear)
        let maxTrimRequest = "/maxtrim";        
        const  { data : lastTrim }  =  await API.get(maxTrimRequest);
        console.log ("Last Trim number :", lastTrim[0].last_trim);
        let newTrim = {
            trimId : lastTrim[0].last_trim + 1,
            modelId : this.state.selectedModelId,
            Name : trim,
            startYear : startYear,
            endYear : endYear,
            bodyStyle : bodyStyle,
            carType : this.state.vehicleSize
        };
        let trimAdd = "/trims";        
        const  { data : trims }  =  await API.post(trimAdd, newTrim);

        this.setState({showTrim : false});

    };
        
    handleMakeChange = (event) => {
        const { value } = event.target;      
        // When make changes, read the new model list from the database and reset the trims
        const dispModel = this.state.makes.filter(make => make.name === value);
        console.log ("Selected Make :", dispModel);
        this.readModels(dispModel);
        this.setState( {selectedMake : value, selectedMakeId : dispModel[0].id_car_make, selectedTrim : "Trim", trims : []});
    }

    handleModelChange = (event) => {
        const { value } = event.target;      
        // When model changes, read the new trim list from the database
        this.setState( {selectedModel : value});
        const dispTrim = this.state.models.filter(model => model.name === value);
        this.readTrims(dispTrim);
        let sizeIndex = this.state.models.findIndex(model => model.name === value);
        this.setState( { vehicleSize : this.state.models[sizeIndex].vehicleSize, selectedModelId : this.state.models[sizeIndex].id_car_model} );

    }
    
    handleTrimChange = (event) => {
        const { value } = event.target;      
        this.setState( {selectedTrim : value});
        console.log("Selected Trim: ", value)
        const dispTrim = this.state.models.filter(model => model.name === value);
    }

    handleCancel = (event) => {
        this.setState({showMake : false, showModel : false, showTrim : false})
    }

    render() {  
        
        // make sure to only include the makes that are applicable
        var makeList = this.state.makes.map(a=>a.name);
        makeList.unshift("Make");
        // make sure to only include the makes that are applicable
        var modelList = this.state.models.map(a=>a.name);
        modelList.unshift("Model");
        // make sure to only include the makes that are applicable
        var trimList = this.state.trims.map(a=>a.name + " " + a.body_style);

        trimList.unshift("Trim");
        const backgroundStyle= {backgroundColor : '#FFD9AC', height : "450px"};
        const blockHeight = {height : "200px"};
        const greyBackround = {backgroundColor : "#FFFFFF", height : "200px"};

        return (  

            <React.Fragment>
                <h4 className='text-center'>Manage vehicle catalog</h4>
                <form className="bg-complete shadow  p-5 corners">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2">
                        <Select 
                            name="makes"
                            label=" "
                            options={makeList}
                            value={this.state.selectedMake}
                            onChange={this.handleMakeChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="models"
                            label=" "
                            options={modelList}
                            value={this.state.selectedModel}
                            onChange={this.handleModelChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="trims"
                            label=" "
                            options={trimList}
                            value={this.state.selectedTrim}
                            onChange={this.handleTrimChange}
                            />
                        </div>
                    </div>
                    </form>
                    <div className="col text-center">
                    <button type="button" className="btn shadow btn-success m-2 buttons" onClick={this.showMake} >Add Make</button>             
                    {this.state.selectedMake !== "Make" && <button type="button" className="btn shadow btn-success m-2 buttons" onClick={this.showModel} >Add Model</button>}             
                    {this.state.selectedModel !== "Model" && <button type="button" className="btn shadow btn-success m-2 buttons" onClick={this.showTrim} >Add Trim</button>}             
                    </div>
                    <AddMake 
                        showMake={this.state.showMake}
                        onHide={this.handleCancel}
                        addMake={this.addMake}
                    />
                    <AddModel 
                        showModel={this.state.showModel}
                        onHide={this.handleCancel}
                        addModel={this.addModel}
                    />
                    <AddTrim 
                        showTrim={this.state.showTrim}
                        onHide={this.handleCancel}
                        addTrim={this.addTrim}
                    />

            </React.Fragment>
        );
    };

};
