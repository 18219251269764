import React, { Component } from 'react'
// import { Auth } from "aws-amplify";

export default class componentName extends Component {

  // state = {
  //   userEmail: ''
  // };

  // async componentDidMount() {
  //   const CognitoUser = await Auth.currentAuthenticatedUser();
  //   const userEmail = CognitoUser.attributes.email
  //   this.setState({ userEmail })
  // }

  render() {
    return (
      <React.Fragment>
        <nav className="navbar bg-white shadow-sm navbar-light mb-4 d-none d-lg-block">
          <span className="navbar-brand">{this.props.title}</span>
          {/* <span className="navbar-text float-right">{this.state.userEmail}</span> */}
          <span className="navbar-text float-right">{this.props.userName}</span>
        </nav>
      </React.Fragment>
    )
  }
}
