// format the date to display like a caledar icon


import React from 'react';
import moment from 'moment';
import './../../assets/css/dateDisplay.css';

const dateDisplay = (passedDate) => {

let weekday = new Array(7);
weekday[0] =  "Sun";
weekday[1] = "Mon";
weekday[2] = "Tue";
weekday[3] = "Wed";
weekday[4] = "Thu";
weekday[5] = "Fri";
weekday[6] = "Sat";

let monthName = new Array(12);
monthName[0] =  "Jan";
monthName[1] =  "Feb";
monthName[2] =  "Mar";
monthName[3] =  "Apr";
monthName[4] =  "May";
monthName[5] =  "Jun";
monthName[6] =  "Jul";
monthName[7] =  "Aug";
monthName[8] =  "Sep";
monthName[9] =  "Oct";
monthName[10] =  "Nov";
monthName[11] =  "Dec";

    const today = new moment().format("MM/DD/YYYY");
    const formatdate = new moment(passedDate).format("MM/DD/YYYY");

    const date = new Date(passedDate);
    const dayOfWeek = weekday[date.getDay()];
    const daynum = date.getDate();
    const month = monthName[date.getMonth()];


    if (today === formatdate) {
        return (<time dateTime={date} className="icon">
                <strong className="day">{dayOfWeek}</strong>
                <span>{daynum}</span>
                <em>{month}</em>
                </time>)
    } else {
        return (<time dateTime={date} className="icon">
                <strong>{dayOfWeek}</strong>
                <span>{daynum}</span>
                <em>{month}</em>
                </time>)

    }
}

export default dateDisplay;
