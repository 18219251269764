import React, { Component } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import { HTTPService } from "./../../services/httpService";
const API = new HTTPService();


export class CheckOut extends Component {

    state = {
      paymentState : "Not Started",
      terminal : "Offline"
    };
      // build the display for the checkout
/*      
      let thisCart = {
        type: 'cart',
        cart: {
          currency: 'usd',
          line_items: [],
          tax: 0,
          total: 0,
        }
      };
      let lineItem = {
        amount : 0,
        description : "",
        quantity : 1
      };
      
      let items = this.props.invoice.lines.data;
      console.log("Here's the items on the invoice :", items);
      console.log("there are ", items.length, " of them");
      for (let i=0; i<items.length; i++){
        let lineItem = {
          amount : 0,
          description : "",
          quantity : 1
        };
        lineItem.amount = items[i].amount;
        lineItem.description = items[i].description;
        thisCart.cart.line_items.push(lineItem);
      }
      thisCart.cart.total = this.props.invoice.total;
      thisCart.cart.tax = this.props.invoice.tax;
            
      console.log("Here's the items on the invoice :", thisCart);

      let terminalRequest = `/terminal`;
      let terminalBody = {
        terminalId : this.props.installer.stripe_location,
        cart : thisCart
      }
      const { data: terminalRequestData } =  API.post(terminalRequest, terminalBody);
*/

      checkTerminal = async (terminal) => {
        console.log( "This is the terminal ID : ", terminal)

        let terminalCheck = `/terminal/${terminal}`;
        const { data: terminalData2 } = await API.get(terminalCheck);
        console.log ("Returned terminal data:" ,terminalData2)
        if (terminalData2.status === "online")
          this.setState({terminal : "Online" });
      };

      checkSwipe = async (payment) => {
        console.log( "CheckSwipe : ", this.props.payment);
        if (this.props.payment){
        let paymentCheck = `/payments/${this.props.payment}`;
        const { data: paymentData } = await API.get(paymentCheck);
//        console.log ("Returned payment data:" ,paymentData)
        if (paymentData.status === "succeeded") {
// close out the invoice
            const apiEndpoint =  "/invoices/settle/"+this.props.invoice.id;
            const {data:post} = await API.put(apiEndpoint);
            this.setState({paymentState : "Success" });
        }
          if (paymentData.status === "failed")
          this.setState({paymentState : "Error" });
        return(paymentData);
        }
      };

      collectPayment = async (payment) => {
        console.log("Collect the payment");
        let newPayment = {
          paymentId : this.props.payment,
          terminalId : this.props.installer.stripe_location,
        }  

        console.log( "This is the payment and terminal ID : ", newPayment);
        let paymentCollect = `/terminalPay/`;
        const { data: intentData } = await API.post(paymentCollect, newPayment);
        console.log ("Returned Terminal request:" ,intentData)
        this.setState({paymentState : "Started" });

        return(intentData);
      };
  

    handleCancelAttempt = (terminal) => {
      console.log ("Cancel the Payment Attempt");
      let terminalRequest = `/terminal/${this.props.installer.stripe_location}`;
      const { data: terminalRequestData } =  API.post(terminalRequest);
      this.setState({paymentState : "Not Started" });
      return(terminalRequestData);
    };

    handleSubmit = (event) => {
      console.log ("close this up");
       this.props.onComplete();  
      };
    
    render(){
      var imageName = require('./../../assets/Jobvious_logo.png');

      console.log ("Checkout : Installer :", this.props.installer);
      console.log ("Invoice :", this.props.invoice);
      console.log ("Payment :", this.props.payment);

      // First thing to do is check that the terminal is online
      // if it is, send the payment to the terminal
      // if declined, either cancel or try again
      // if successful, update the invoice
      let message1 = "";
      let message2 = "";
      if (this.state.terminal === "Offline") {
        message1 = "Connect to Card Terminal"
        message2 = "Check reader is powered on & click Check Reader button";
      } else {
        switch (this.state.paymentState){
          case "Not Started" :  message1 = "Amount Due : $"+ this.props.invoice.amount_due/100;
          message2 = "from " + this.props.invoice.customer_name; break;
          case "Started" :  message1 = "Waiting for customer";
          message2 = ""; break;
          case "Error" :  message1 = "Issue with payment";
          message2 = ""; break;
          case "Success" :  message1 = "Payment accepted";
          message2 = ""; break;
          default: break;
        }
      }
     
      return(
            <Modal
            show={this.props.show}
            onHide={this.props.onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
            <div className="logosize text-center mb-3 mt-2">
                <img src={imageName} alt="" />
            </div>            
            </Modal.Header>
            <Modal.Body>
                <h3>{message1}</h3>
                <h4>{message2}</h4>
                {this.state.terminal === "Offline" && <Button type="submit" onClick={() => this.checkTerminal(this.props.installer.stripe_location)}>Check Reader</Button>}
                {(this.state.terminal === "Online" && this.state.paymentState === "Not Started") && <Button type="submit" onClick={() => this.collectPayment(this.props.payment)}>Send to Reader</Button>}
                {(this.state.terminal === "Online" && this.state.paymentState === "Started") && <div><Button type="submit" onClick={() => this.checkSwipe(this.props.payment)}>Check Status</Button><Button onClick={() => this.handleCancelAttempt()}>Cancel</Button></div>}
                {(this.state.terminal === "Online" && this.state.paymentState === "Error") && <div><Button type="submit" onClick={() => this.checkSwipe(this.props.installer.stripe_location)}>Re-Try</Button><Button onClick={() => this.handleCancelAttempt()}>Cancel</Button></div>}
                {(this.state.terminal === "Online" && this.state.paymentState === "Success") && <Button type="submit" onClick={() => this.handleSubmit()}>Close</Button>}
            </Modal.Body>
          </Modal>
        );
    };
};