import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { Auth } from "aws-amplify";

class navTopBar extends Component {
  state = {
    menu: false,
    namePage: 'Requestimates',
    selectedRequestimate: {}
  };

  setNamePage = namePage => {
    // const namePage = namePage;
    this.setState({ namePage });
    this.setState({ menu: false });
  }

  openMenu = async () => {
    this.setState({ menu: true });
  }

  closeMenu = async () => {
    this.setState({ menu: false });
  }

  doSubmit = async () => {
    Auth.signOut().then(() => {
      localStorage.clear();
      this.props.history.push('/login')
    }).catch(err => {
      console.log(err);
    });
  }

  handleCancel = () => {
    this.setState({ selectedRequestimate: {} });
  }

  render() {
    const divStyle = {
      // height: "275px",
      backgroundColor: '#FFD9AC'
    }
    if (this.state.namePage === "Jobs") {
      divStyle.backgroundColor = "#98CBE5";
    };

    // let userName = localStorage.getItem('userName');
    var imageName = require('./../../assets/Jobvious_white_logo_base.svg');
    if (this.state.menu) {
      return (
        <React.Fragment>
          <Navbar onToggle={this.closeMenu} expanded={true} bg="dark" variant="dark" expand="lg" className="bg-menu shadow-sm mb-2">
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <div className="navbar-brand">
              <img src={imageName} alt="" height="40" className="d-inline-block align-top mt--2" />
            </div>

            <Navbar.Collapse id="basic-navbar-nav" className="pt-5">
              <Nav className="mr-auto">
                <li>
                  <NavLink activeClassName="active" onClick={() => this.setNamePage('Requestimates')} className="text-light" to="/requestimates">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i> Requestimates
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" onClick={() => this.setNamePage('Jobs')} className="text-light" to="/jobs">
                    <i className="fa fa-bar-chart" aria-hidden="true"></i> Jobs
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" onClick={() => this.setNamePage('Pickups')} className="text-light" to="/pickups">
                    <i className="fa fa-bar-chart" aria-hidden="true"></i> Pickups
                  </NavLink>
                </li>
                {this.props.userType === "installer" &&
                  <li>
                    <NavLink activeClassName="active" onClick={() => this.setNamePage('Calendar')} className="text-light" to="/calendar">
                      <i className="fa fa-calendar" aria-hidden="true"></i> Calendar
                    </NavLink>
                  </li>
                }
              </Nav>

              <div className="text-center mt-5">
                <button className="btn btn-link text-white" onClick={this.doSubmit}>
                  Sign Out
                </button>
              </div>
            </Navbar.Collapse>
          </Navbar>
          {/* <h3 className="page-name" style={divStyle}>{this.state.namePage}</h3> */}
        </React.Fragment>);
    } else {
      return (<React.Fragment>
        <div className="pos-f-t text-center">
          <nav className="navbar navbar-light bg-light text-center">
            <button className="navbar-toggler" type="button" onClick={this.openMenu}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <span className="navbar-brand">{this.props.title}</span>
          </nav>
        </div>
        {/* <h3 className="page-name" style={divStyle}>
          {this.state.namePage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleCancel() }>
            <span aria-hidden="true">&times;</span>
          </button>
        </h3> */}
      </React.Fragment>)
    }
  }
}

export default withRouter(navTopBar);
