import jstz from 'jstz';
import moment from 'moment-timezone';

const tzName = jstz.determine().name();

const convertToUTC = (date) => {

  const localTime = moment.tz(date, tzName);
  return localTime.clone().tz("UTC");

}

const convertFromUTC = (date) => {

  const UTCTime = moment.tz(date, "UTC");
  return UTCTime.clone().tz(tzName);

}

export { convertFromUTC, convertToUTC };