import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { HTTPService } from "./../../../services/httpService";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import CalendarCard from './calendarCard';
//import { GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';


const API = new HTTPService();

class Calendar extends Component {
    state = {
        installer : {},
        calendarDays : [],
        blockedDays : [],
        selectedDate : {},
        today : "",
        thisDay : "",
        thisWeek : "",
        thisMonth : "",
        selectedWeek : "",
        selectedMonth : "",
        startDate : "",
        duration : 0,
        range : "day",
        displayString : "",
        userType : this.props.userType,
        typeId : this.props.typeId,
        calendarDay : {
            calendarDate : "",
            countDraft : 0,
            countPending: 0,
            countBooked: 0
        },   
        displayDays : [],
        loggedIn : false,
    };


    async componentDidMount() {
     // get all the calendar events for the installer from the dealer and walk-ins

     let installerRequest = `/installerLocations/${this.state.typeId}`;        
     const { data: installer } = await API.get(installerRequest);

     const firstOne = installer.shift();
     this.setState({ installer : firstOne });  
        console.log ("Selected Installer Location :", firstOne);
     
         let calendarRequest = `/dealerCalendars/${this.state.typeId}`;
     
         const  { data : dealerCalendarDays }  = await API.get(calendarRequest);
         
         calendarRequest = `/installerCalendars/${this.state.typeId}`;
     
         const  { data : installerCalendarDays }  = await API.get(calendarRequest);

         var calendarDays = dealerCalendarDays.concat(installerCalendarDays);

         calendarDays = _.orderBy(calendarDays,['workDate'], ['asc']);

         // filter the calendar to only show data from today forward
         var today = new moment.utc();
         var start = new moment.utc();
         this.setState( { today })
   //      console.log ("Today's date : " , today);
         this.setState( {thisDay : today});
         this.setState( {thisWeek : today.week()});
         var selectedWeek = new moment().format("w");
         this.setState( {selectedWeek} );
         this.setState( {thisMonth : today.month()});
         var selectedMonth = new moment().format("M");
         this.setState( {selectedMonth} );
         this.setState({ displayString : today.format("ddd, MMM Do YYYY")});
         this.setState ({startDate : start})
         this.setState({ calendarDays});

// set up the blocked days 
         calendarRequest = `/installerBlockedCalendars/${this.state.typeId}`;
         const  { data : blockedDays }  = await API.get(calendarRequest);
         this.setState ( {blockedDays});
 //        console.log ("Blocked Days : ", blockedDays)
 //        console.log ("Calendar : Calendar : ", calendarDays)
    };

    handleClick = (event) => {
        const { value } = event.currentTarget;
        this.setState({ selectedDate: value });
    };

    navigate = async (url) => {
        console.log("this is where we need to go", url);
        window.location.href= url;
    }

    authorizeGoogle = async () => {
        // send this key to the back end to get the tokens and store them with the installer
        let userSetup = `/request`;        
        const { data: creds } = await API.get(userSetup);
 //       console.log("Response from Google : ", creds)
        this.navigate(creds)
    };

    handleRange = (event) => {
        const {value} = event.currentTarget;
//          console.log ("Button Clicked :" , value);
        var duration = 0;
        var displayString = "";
        var newDay = moment(this.state.selectedDate);

        if (this.state.range === "day") {
            if (value === "week") {
                newDay = moment(this.state.selectedDate).startOf('week');
                duration = 6;                 
            }
            if (value === "month") {
                newDay = moment(this.state.selectedDate).startOf('month');
                duration = moment(newDay).daysInMonth() - 1;  // set based on length of month
            }
        }
        if (this.state.range === "week") {
            if (value === "day") {
            // set the day to beginning of week
                newDay = moment(this.state.selectedDate).startOf('week');
                duration = 0;                 
            }
            if (value === "month") {
                newDay = moment(this.state.selectedDate).startOf('month');
                duration = moment(newDay).daysInMonth() - 1;  // set based on length of month
            }
        }
        if (this.state.range === "month") {
            if (value === "week") {
                newDay = moment(this.state.selectedDate).startOf('month');
            // set the week to first week in month
                duration = 6;
            }
            if (value === "day") {
                newDay = moment(this.state.selectedDate).startOf('month');
                duration = 0;                 
            }
        }
// set the start and end date for display based on range selected
        var endDate = moment(newDay).add(duration, "days");
        if (duration === 0) {
            displayString = newDay.format("ddd, MMM Do YYYY")            
        } else {
            displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
        }
        this.setState({ range : value , duration, selectedDate : newDay, displayString});

    };

    handleDisplayRange = (event) => {
        const {value} = event.currentTarget;
//        console.log ("Direction to travel :" , value);
//        console.log ("range :", this.state.range);
        var newDay = moment(this.state.selectedDate);
        var newWeek = this.state.selectedWeek;
        var newMonth = this.state.selectedMonth;
        var displayString = "";

// if the range is "day", then we are inc/dec the start date, duration is 1 day
        if (this.state.range === "day") {
            if (value === "today"){
                newDay = new moment.utc();
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                    console.log("Start Date : ",newDay);
//                    console.log("Selected week :", newWeek);
//                    console.log("Selected Month : ", newMonth);
    
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "day");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                    console.log("Start Date : ",newDay);
//                    console.log("Selected week :", newWeek);
//                    console.log("Selected Month : ", newMonth);
            }
            if (value === "more"){
                newDay = newDay.add(1, "day");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
            } 
//            console.log("Start Date : ",newDay);
//            console.log("Selected week :", newWeek);
//            console.log("Selected Month : ", newMonth);
 //           console.log("Duration :", this.state.duration);
            displayString = newDay.format("ddd, MMM Do YYYY");
        }
        if (this.state.range === "week") {
            if (value === "today"){
                newDay = new moment.utc();
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                  console.log("Start Date : ",newDay);
//                  console.log("Selected week :", newWeek);
//                  console.log("Selected Month : ", newMonth);
    
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "week");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                   console.log("Start Date : ",newDay);
//                   console.log("Selected week :", newWeek);
//                   console.log("Selected Month : ", newMonth);
            }
            if (value === "more"){
                newDay = newDay.add(1, "week");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                   console.log("Start Date : ",newDay);
//                   console.log("Selected week :", newWeek);
//                   console.log("Selected Month : ", newMonth);
            } 
            var endDate = moment(newDay).add(this.state.duration, "days");
            displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
//                   console.log("End Date : ", endDate);

        }

        if (this.state.range === "month") {
            if (value === "today"){
                newDay = new moment.utc();
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                    console.log("Start Date : ",newDay);
//                    console.log("Selected week :", newWeek);
//                    console.log("Selected Month : ", newMonth);
            }
            if (value === "less"){
                newDay = newDay.subtract(1, "month");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                    console.log("Start Date : ",newDay);
//                    console.log("Selected week :", newWeek);
//                    console.log("Selected Month : ", newMonth);
            }
            if (value === "more"){
                newDay = newDay.add(1, "month");
                newWeek = newDay.format("wo");
                newMonth = newDay.format("Mo");
//                    console.log("Start Date : ",newDay);
//                    console.log("Selected week :", newWeek);
//                    console.log("Selected Month : ", newMonth);
            } 
            endDate = moment(newDay).add(this.state.duration, "days");
//            console.log("End Date : ", endDate);
            displayString = newDay.format("ddd, MMM Do YYYY") + " - " + endDate.format("ddd, MMM Do YYYY")            
        }
        this.setState( {selectedDate : newDay, selectedWeek : newWeek, selectedMonth : newMonth, displayString});

    };

    selectStyle = (date) => {
        const style = {};
        if (this.state.selectedDate === date) {
            style.backgroundColor = '#dde3e9';
        } else {
            style.backgroundColor = '#ffffff';
        }
        return style;
    };

    render() {
        // table definition for display of the table
        const columns = [{
            dataField: 'startDateString',
            text:"Start Date"},{
            dataField: 'dueDateString',
            text:"Due Date"},{
            dataField: 'workString',
            text:"Work to be done"},{
            dataField: 'estimate',
            text:"Time(in Days)"}];

        const rowStyle2 = (row, rowIndex) => {
            const style = {};
                // add grey in here for draft
                if (row.requestState === "draft" && row.requestType==="installer") {
                    style.backgroundColor = '#A2E6C3';
                };      
                if (row.requestState === "draft" && row.requestType==="dealer") {
                    style.backgroundColor = '#A0A1A2';
                };      
                if (row.requestState === "pending") {
                    style.backgroundColor = '#FFD9AC';
                };      
                if (row.requestState === "booked") {
                    style.backgroundColor = '#98CBE5';
                };      
            return style;
        };

        var appointments = this.state.calendarDays;
        console.log ("Calendar : Appointments :" , appointments);
        var open = 0;
        var unanswered = 0;
        var booked = 0;
        var usedCapacity = 0;

        let displayWorkTable= [];

        var startDate = moment(this.state.selectedDate);
        var endDate = moment(this.state.selectedDate).add(this.state.duration, "days");

        for (let i=0; i < appointments.length; i++) 
        {
            const view = {
                viewKey : "",
                requestId : "",
                serviceId : "",
                requestState : "",
                requestType : "",
                startDate : "",
                startDateString: "",
                dueDate : "",
                dueDateString: "",
                workString: "",
                estimate:"",
                eventId:""
            };
            if (moment(appointments[i].workDate).isBetween(startDate.startOf("day"), endDate.endOf("day")))
            { 
// filter out the services based on where they came from    
// if dealer job created by dealer, use serviceCatalog = 0
// if dealer job created by installer, use serviceCatalog for installer and WHOLE
// if installer job created by installer, use serviceCatalog for installer and RETAIL            

                view.requestState = appointments[i].workStatus;
                switch(view.requestState) 
                {
                case "draft" :  if (appointments[i].requestType === "installer") 
                                    open ++
                                    else
                                    unanswered++; break;
                case "booked" : booked++; break;
                case "started" : booked++; break;
                default : break;
                }
                view.viewKey = appointments[i].requestId.toString() + appointments[i].serviceId.toString() + appointments[i].workDate;
                view.requestType= appointments[i].requestType;
                view.requestId = appointments[i].requestId;
                view.serviceId = appointments[i].serviceId;
                view.startDate = appointments[i].workDate;
                view.startDateString = moment(appointments[i].workDate).format("ddd Do MMMM");
                view.dueDate = appointments[i].dueDate;
                view.dueDateString = moment(appointments[i].dueDate).format("ddd Do MMMM");
                view.workString = appointments[i].category + " " + appointments[i].description + " on " + appointments[i].year + " " + 
                appointments[i].make + " " + appointments[i].model + " " + appointments[i].trim + " for "
                + appointments[i].dealerName;
                usedCapacity = usedCapacity + appointments[i].duration;
                view.estimate = appointments[i].duration;
                view.eventId = appointments[i].eventId;
//                console.log("Used Capacity :", usedCapacity)
    //                console.log("Add to array :", view);
                displayWorkTable.push(view);
            }
        }

        let unqDays = displayWorkTable.map(i => i.startDateString);
        const uniques = Array.from(new Set(unqDays));

        const divStyle = { backgroundColor : "#dde3e9"};
        const estStyle = { backgroundColor : '#A2E6C3'}
        const reqStyle = { backgroundColor : '#FFD9AC'}
        const jobStyle = { backgroundColor : '#98CBE5'}

        // calculate the percentages for the appropriate disply
    // installer Capacity is # of employees.

        var dailyCapacity = this.state.installer.capacity;  
        var actualCapacity = dailyCapacity;
        if (this.state.duration > 0) 
        {
            actualCapacity = dailyCapacity * this.state.duration
        };
        
        var availableCapacity = (actualCapacity - usedCapacity)/actualCapacity * 100;

/*      console.log("Range Selected :", this.state.range);
        console.log("Duration : ",this.state.duration);
        console.log("Start Date : ",this.state.startDate);
        console.log("Selected week :", this.state.selectedWeek);
        console.log("Selected Month : ", this.state.selectedMonth);
        console.log("Available Capacity :", availableCapacity);
        console.log("today :", this.state.today);*/
        displayWorkTable.sort((a,b) => ((a.startDate > b.startDate) || (a.startDate === b.startDate && a.dueDate > b.dueDate)))


// if there is no refresh token set for the installer then we need to call the server to get it and update it
// and we redirect back to the calendar page with the refresh taken then set 


        return (
            <React.Fragment>
            <div className="container-flex m-3">
            <div className="row" style={divStyle}>
                <div className="col text-left w-2">
                <ButtonGroup className="mb-2">
                    <Button variant="outline-primary" onClick={this.handleRange} value="day" active={this.state.range === "day"} >Day</Button>
                    <Button variant="outline-primary" onClick={this.handleRange} value="week" active={this.state.range === "week"} >Week</Button>
                    <Button variant="outline-primary" onClick={this.handleRange} value="month" active={this.state.range === "month"}>Month</Button>
                </ButtonGroup>
                </div>
                <div className="col text-center w-6">
                    <h6>{this.state.displayString}</h6>
                </div>
                <div className="col text-right w-2">
                <ButtonGroup className="mb-2">
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} value="less"><i className="fa fa-caret-left"></i></Button>
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} active={moment(this.state.selectedDate).isSame(this.state.today, 'day')} value="today">Today</Button>
                    <Button variant="outline-primary" onClick={this.handleDisplayRange} value="more"><i className="fa fa-caret-right"></i></Button>
                </ButtonGroup>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <Link to="/newestimate/" className="btn btn-success">New Estimate</Link>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <hr/>
                </div>
            </div>
            <div className="row" >
                    <div className="col text-center">
                        <div className="card-deck">
                            <div className="card shadow w-25 corners" style={estStyle} >
                                <div className="card-text">
                                    <h5>Estimates</h5>
                                    <div className="row" >
                                        <div className="col">Open : {open}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners" style={reqStyle} >
                                <div className="card-text">
                                    <h5>Requests</h5>
                                    <div className="row" >
                                        <div className="col">Unanswered : {unanswered}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners" style={jobStyle}>
                                <div className="card-text">
                                    <h5>Services</h5>
                                    <div className="row" >
                                        <div className="col">Booked : {booked}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners">
                                <div className="card-text">
                                    <h5>Available Capacity</h5>
                                    <div className="row" >
                                        <div className="col">{availableCapacity.toFixed(2)}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="row">
                <div className="col">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                {uniques.map((value,index) => 
                        (<CalendarCard 
                        date={value}
                        x={index}
                        workTable={displayWorkTable}
                    />))}
                </div>
            </div>
            </div>
            </React.Fragment>
        );
    }
}


export default Calendar;
