import React, { Component } from 'react';
import Select from './../common/select';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export class AddDiscount extends Component {

    state = {
      discountAmount : 0,
      discountPercent : 0,
      discounts : [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
      amountError: "",
      validated : false
    };

    validate = () => {
      let amountError = "";
      if(this.state.discountAmount > 0 && this.state.discountAmount > this.props.jobTotal){
        amountError = "Discount cannot be greater than Job Total"
      }
      if (this.state.discountAmount > 0 && this.state.discountPercent > 0){
        amountError = "Must select either an amount OR percent"
      }

      if (amountError){
        this.setState({amountError});
        return false;
      }

      return true;
    };

    handleSubmit = (event) => {
          event.preventDefault();
          const isValid = this.validate();
          if(isValid) {
            let discountPercent = this.state.discountPercent;
            this.setState({amountError : ""});
            if (this.state.discountAmount > 0){
              discountPercent = this.state.discountAmount / this.props.jobTotal * 100;
            }
            this.props.handleDisc(discountPercent);  
          };
    };

    handleChange = (event) => {
      event.preventDefault();
      let discountAmount = event.target.value;
      this.setState({discountAmount});
    }

    handleSelect = (event) => {
      const { value } = event.target;      
      console.log("Selected discount :", value);
      this.setState( {discountPercent : value });

    };

    render(){
      console.log("Values we have :", this.state.discountAmount, this.state.discountPercent);
      let message = "Enter Discount (% or Amount less than $"+this.props.jobTotal+")";
      return(
            <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {message}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col sm={6}>
                    <form onSubmit={this.handleSubmit}>
                    <div className='group align-center'>
                        <label htmlFor='amount'>Enter Discount Amount $ : </label>
                            <input type='number' autoFocus name='amount' 
                                value={this.state.discountAmount} 
                                onChange={this.handleChange}
                                error={this.state.amountError}/>
                                {this.state.amountError && <div className="alert alert-danger">{this.state.amountError}</div>}
                      </div>
                      <div className='group align-center'>
                        <label htmlFor='amount'>OR Select Discount % : </label>
                            <Select 
                            name="discount"
                            label=" "
                            options={this.state.discounts}
                            value={this.state.discountPercent}
                            onChange={this.handleSelect}
                            />
                    </div>
                    <Button type="submit" className="buttons" onClick={this.handleSubmit}>Apply Discount</Button>
                    </form>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        );
    };
};