import React, { Component } from 'react';

import {Modal, Button, Row, Col, Form} from 'react-bootstrap';

export class AddCustomCut extends Component {


    state = {
      estimateCost : 0,
      estimateDesc : "",
      estimateDuration : 0
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let estimateCost = event.target.EstimateCost.value;
        let estimateDesc = event.target.EstimateDesc.value;
        let estimateDuration = event.target.EstimateDuration.value;
        console.log("Here's what we got : ", estimateDuration, estimateCost);
        this.setState({ estimateCost, estimateDesc, estimateDuration});
        this.props.handleCustomEstimate(estimateDesc, estimateCost, estimateDuration);
    };

    render(){
      console.log("Values we have :", this.state.estimateCost, this.state.estimateDuration);
        return(
          <div>
            <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Enter the Description, Cost and Time Estimate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col sm={6}>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="NewEstimate">
                      <Form.Label>Description of Service</Form.Label>
                        <Form.Control
                        type="text"
                        name="EstimateDesc"
                        required
                        placeholder="This will show on the invoice"
                        />
                      <Form.Label>Cost Estimate</Form.Label>
                        <Form.Control
                        type="text"
                        name="EstimateCost"
                        required
                        placeholder="Enter $ amount"
                        />
                      <Form.Label>Duration Estimate</Form.Label>
                        <Form.Control
                        type="text"
                        name="EstimateDuration"
                        required
                        placeholder="Enter time in days"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Button variant="primary" type="submit" className="buttons">
                          Add Custom Service
                        </Button>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          </div>
        );
    };
};