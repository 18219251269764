import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import { Auth } from "aws-amplify";
import Badge from 'react-bootstrap/Badge';
import "./../../assets/css/navbar.css";
import { HTTPService } from "./../../services/httpService";

const API = new HTTPService();
  
class navBar extends Component {
  doSubmit = async () => {
    Auth.signOut().then(() => {
      localStorage.clear();
      this.props.history.push('/login')
    }).catch(err => {
      console.log(err);
    });
  }

  state = {
    countsReturned : [],
    requestCount : 0,
    jobCount : 0,
    pickupCount : 0,
    authenticated : 0,
  };

  async componentDidMount() {
    // get the counts for the lists
  let countRequest = "/counts/?userId=" + this.props.userId + "&userType=" + this.props.userType;
        
  const  { data : countsReturned }  = await API.get(countRequest);

  this.setState({ countsReturned });

  console.log ("Navbar: counts : ", countsReturned);
  var requestCount = 0;
  var jobCount = 0;
  var pickupCount = 0;

  for (var i=0; i < this.state.countsReturned.length; i++) {
    switch (this.state.countsReturned[i].current_state) {
      case "new" : requestCount = requestCount + this.state.countsReturned[i].total; break;
      case "answered" : requestCount = requestCount + this.state.countsReturned[i].total; break;
      case "scheduled" : jobCount = jobCount + this.state.countsReturned[i].total; break;
      case "received" : jobCount = jobCount + this.state.countsReturned[i].total; break;
      case "started" : jobCount = jobCount + this.state.countsReturned[i].total; break;
      case "finished" : pickupCount = pickupCount + this.state.countsReturned[i].total; break;
      default: break;
    }
    if (this.props.userType === "dealer" && this.state.countsReturned[i].current_state === "collected") {
      pickupCount = pickupCount + this.state.countsReturned[i].total;
    }
  }
  if(this.props.userType === "installer")
    await this.readAuth(this.props.userId);

  this.setState ({requestCount,jobCount,pickupCount});
  
  }


  readAuth = async (installerId) => {
    let installerRequest = `/installerAuth/${installerId}`;        
    const  { data : installer }  =  await API.get(installerRequest);
    console.log("Installer :", installer);
    this.setState( {authenticated : installer[0].authenticated});
  }


  navigate = async (url) => {
    console.log("this is where we need to go", url);
    window.location.href= url;
}

authorizeGoogle = async () => {
    // send this key to the back end to get the tokens and store them with the installer
    let userSetup = `/request`;        
    const { data: creds } = await API.get(userSetup);
//       console.log("Response from Google : ", creds)
    this.navigate(creds)
};


  render() {
    var imageName = require('./../../assets/Jobvious_white_logo_base.svg');
    // let userName = localStorage.getItem('userName');
    console.log("Here's the user id :", this.props.userId);
    return (
      <React.Fragment>
        <div className="main-sidebar">
          <div className="logosize text-center mb-3 mt-2">
            <img src={imageName} alt="" />
          </div>
          <ul className="list-unstyled">
            <li>
              <NavLink activeClassName="active" className="text-light" to={{ pathname: "/requestimates", selectedRequestimate: {} }}>
              {this.state.requestCount > 0 && <Badge pill variant="danger">{this.state.requestCount}</Badge>} Requestimates
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" className="text-light" to={{ pathname: "/jobs", selectedRequestimate: {} }}>
              {this.state.jobCount > 0 && <Badge pill variant="danger">{this.state.jobCount}</Badge>} Jobs
              </NavLink>
            </li>
            {this.props.userType === "dealer" && <li><NavLink  activeClassName="active" className="text-light" to="/pickups">{this.state.pickupCount > 0 && <Badge pill variant="danger">{this.state.pickupCount}</Badge>} Pickup/Review</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/pickups">{this.state.pickupCount > 0 && <Badge pill variant="danger">{this.state.pickupCount}</Badge>} Pickup/Deliver</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/calendar">Calendar</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/financials">Financials</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/customers">Customers</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/services">Services</NavLink></li>}
        {this.props.userType === "installer" && <li><NavLink  activeClassName="active" className="text-light" to="/vehicles">Vehicles</NavLink></li>}
        {this.props.userType === "dealer" && <li><a className="text-light" href="https://www.jobvious.com/dealer-support-page" target="_blank" rel="noopener noreferrer">System Help</a></li>}
        {this.props.userType === "installer" && <li><a className="text-light" href="https://www.jobvious.com/specialist-support-page" target="_blank" rel="noopener noreferrer">System Help</a></li>}
          </ul>

          {!this.state.authenticated && this.props.userType === "installer" && <div className="mt-5 mb-5 text-center">
            <button className="btn btn-success btn-primary-green rounded-pill" onClick={this.authorizeGoogle}>
              Connect Google Calendar 
            </button>
          </div>}

          <div className="mt-5 mb-5 text-center">
            <button className="btn btn-success btn-primary-green rounded-pill" onClick={this.doSubmit}>
              Sign Out
            </button>
          </div>
          <div className="version text-center text-light">
            v 2.2
          </div>
          <div className="version text-center">
          <a className="text-light" href="https://s3.us-east-2.amazonaws.com/jobvious.digitalassets/Jobvious.Privacy.Policy.10.6.21.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </div>
          <div className="version text-center">
          <a className="text-light" href="https://s3.us-east-2.amazonaws.com/jobvious.digitalassets/Jobvious.Limited.Use.Policy.7.10.24.pdf" target="_blank" rel="noopener noreferrer">Google API Policy</a>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default withRouter(navBar);
