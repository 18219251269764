import React, { Component } from 'react';
import JOI from 'joi-browser';
import Select from './common/select';
import { HTTPService } from "./../services/httpService";

const API = new HTTPService()



export default class CustomerForm extends Component {
    state = { 
        data : {phone : ""},
        errors : {},
        name : '',
        email : "",
        phone : "",
        found : " ",
        customerId: "",
        selectedDealer:"Dealerships",
};

     schema = {
        phone: JOI.string().length(10).required().label('phone')}; 

        async getCustomer(custId) {            

            const customerRequest =  "/customers/"+custId;
            const { data : customer } = await API.get(customerRequest);
            console.log(customer);
            let cust = customer[0];            
            if (customer.length > 0) {
                this.setState( { found : "Y"});
                this.setState( {name : cust.customer_name, email : cust.customer_Email_Address, customerId : cust.customer_id});
                this.props.handleCustomer(cust.customer_id, cust.customer_name, "retail");
            } else {
                this.setState( { found : "N"});
            }
            // need to handle the case where this isn't available
          };

          async addCustomer(name, email, phone) {            
            console.log('Adding Customer Record');
            console.log('Record :', name);
            const customer = {
                customerName : name,
                customerEmailAddress : email,
                customerPhoneNumber : phone,
                customerType : "Prospect"
              };
            console.log(customer);
          
            const apiEndpoint =  "/customers";
            const {data:customerId} = await API.post(apiEndpoint, customer);
            console.log(customerId);
            this.setState({customerId});
        };
            
      validate = () => {
        const options = { abortEarly: false };
        const { error } = JOI.validate(this.state.data, this.schema, options);
        if (!error) return null;
    
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
      };

      validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = JOI.validate(obj, schema);
        return error ? error.details[0].message : null;
      };
    
      handleDealerChange = (event) => {
        const { value } = event.target;      
//        console.log("Selected year :", value);
        this.setState( {selectedDealer : value });

        // selecting the dealer will do the same as selecting the customer
        this.setState( { found : "Y"});
        let dealerIndex = this.props.dealerships.findIndex(dealer => dealer.dealer_name === value);

        this.setState( {name : this.props.dealerships[dealerIndex].dealer_name, 
            email : this.props.dealerships[dealerIndex].dealer_email, 
            customerId : this.props.dealerships[dealerIndex].dealer_id});
        this.props.handleCustomer(this.props.dealerships[dealerIndex].dealer_id, this.props.dealerships[dealerIndex].dealer_name, "whole");

    }

      handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        console.log ("field validate :", input)
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    
        const data = { ...this.state.data };
        data[input.name] = input.value;
    
        this.setState({ data, errors });
      };

      handleNameChange = (e) => {
        const name = e.currentTarget.value;
        this.setState({name});
      }
      
      handleEmailChange = (e) => {
        const email = e.currentTarget.value;
        this.setState({email});
      }
            
      doSubmit = () => {
        // Go and get the customer information from db 
        this.getCustomer(this.state.data.phone);
    };
            
    doAdd = () => {
        // Go and get the customer information from db 
        var newCustomer = this.addCustomer(this.state.name, this.state.email, this.state.data.phone);
        // when the promise is resolved, update the state variables
        newCustomer.then(done => {
            this.setState({found : "Y"});
            this.props.handleCustomer(this.state.customerId, this.state.name, "retail");

        })
    };

    
    render() { 
        if (this.props.currentStep !== 1) {
            return null
        };
        const { loading } = this.state;
        var dealerList = this.props.dealerships.map(a=>a.dealer_name);
        dealerList.unshift("Dealerships");

        console.log("Passed Installer :", this.props.installer);
        let showDealer = false;
        let showRetail = false;
        let customerMessage = "Enter the customer phone number";
        if (this.props.installer > ""){
            if (this.props.installer.services.includes("whole") ) {
                showDealer = true;
                customerMessage = "Select the dealership"
             }
            if (this.props.installer.services.includes("retail") ) {
                showRetail = true;
            };
            if (showDealer && showRetail) 
                customerMessage = "Enter the customer phone number OR select the dealership"
        }
        return (
            <React.Fragment>
                <form className="bg-request p-5">
                <div className="row justify-content-md-center">
                    <h5 className="text-center">{customerMessage}</h5></div>
                    {showRetail && <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            autoFocus 
                            name='phone' 
                            value={this.state.data.phone} 
                            onChange={this.handleChange} 
                            error={this.state.errors['phone']} />
                            {this.state.errors['phone'] && <div className="alert alert-danger">{this.state.errors['phone']}</div>}
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-block btn-success buttons" onClick={this.doSubmit} disabled={this.validate()}>
                            {loading && <span>
                                        <i
                                        className="fa fa-refresh fa-spin"
                                        />
                                    </span>}
                                    {!loading && <span>Lookup</span>}
                                </button>
                        </div>
                    </div>}
                    {showDealer && <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3 text-center">
                            <Select 
                                 menuPlacement="top"
                                 name="dealerships"
                                label=" "
                                options={dealerList}
                                value={this.state.selectedDealer}
                                onChange={this.handleDealerChange}
                                />
                        </div>
                    </div>}
                </form>

                {this.state.found === "Y" &&<div>
                <h5 className="text-center bold p-5">Customer Selected</h5>
                <div className="col-lg-12 bold text-center">
                    <p>{this.state.name}</p>
                    <p>{this.state.email}</p>
                </div></div>}
                
                {this.state.found === "N" && <div className="m-2 shadow">
                <h5 className="text-center bold p-5">Add new Customer Detail</h5>
                <form className="bg-request p-5">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="name" 
                            className="bold mt-1">Full Name</label>
                        </div>
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            name='name' 
                            value={this.state.name} 
                            onChange={this.handleNameChange}/> 
                        </div>
                        </div>
                        <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="email" 
                            className="bold mt-1">Email</label>
                        </div>
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            name='email' 
                            value={this.state.email} 
                            onChange={this.handleEmailChange}/> 
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" 
                            className="btn btn-block btn-success buttons" 
                            onClick={this.doAdd} 
                            disabled={!this.state.email}>
                            Add Customer
                                </button>
                        </div>
                    </div>
                </form>
            </div>}

            </React.Fragment>
        )}};
