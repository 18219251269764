import React, { Component } from 'react';
import JOI from 'joi-browser';
import Select from './common/select';
import { HTTPService } from "./../services/httpService";
import _ from 'lodash';

const API = new HTTPService()



export default class SpecialistForm extends Component {
    state = { 
        data : {phone : ""},
        errors : {},
        name : "Select the specialist",
        email : "",
        phone : "",
        found : " ",
        customerId: "",
        selectedSpecialist:"Specialist",
        specialists:[]
};
    
    handleSpecialistChange = (event) => {
        const { value } = event.target;
        console.log ("Selected Specialist :", value);
        console.log("Passed in Specialists : ", this.props.specialists);
        let returnValue = "0";
        if (value != "Any" && value != "Specialist"){
            let installer = this.props.specialists.findIndex(special => special.Installer_Name ===value)
            returnValue = this.props.specialists[installer].Installer_id;
        }
        
        this.setState( {name : value, selectedSpecialist : value} )
            
        // look up the value of the selected specialist

        this.props.handleSpecialist(returnValue);
    };

    render() { 
        if (this.props.currentStep !== 1) {
            return null
        };
        // read the specialists in the area for the dealership
 //       console.log("Specialists :", this.props.specialists);
        var specialistList = this.props.specialists.map(a=>a.Installer_Name);
        specialistList.unshift("Any");
        specialistList.unshift("Specialist");
        

        let customerMessage = "Select your preferred specialist or ANY";
        return (
            <React.Fragment>
                <form className="bg-request p-5">
                <div className="row justify-content-md-center">
                    <h5 className="text-center">{customerMessage}</h5></div>
                        <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3 text-center">
                            <Select 
                                name="specialist"
                                label=" "
                                options={specialistList}
                                value={this.state.selectedSpecialist}
                                onChange={this.handleSpecialistChange}
                                />
                        </div>
                    </div>
                </form>

                {this.state.selectedSpecialist != "Specialist" && <div>
                    <h5 className="text-center bold p-5">Specialist Selected</h5>
                    <div className="col-lg-12 bold text-center">
                        <p>{this.state.name}</p>
                        <p>{this.state.email}</p>
                    </div>
                </div>}
                

            </React.Fragment>
        )}};
