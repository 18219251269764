import React, { Component } from 'react';
import Select from './../common/select';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export class AddMake extends Component {

    state = {
      make : 0,
      years :['Current', '2020', '2021', '2022', '2023', '2024'],
      startYear : 'Current',
      endYear : 'Current',
      validated : false
    };

    validate = () => {
      let yearError = "";

      if (yearError){
        this.setState({yearError});
        return false;
      }

      return true;
    };

    handleSubmit = (event) => {
          event.preventDefault();
          const isValid = this.validate();
          if(isValid) {
            this.setState({yearError : ""});
            this.props.addMake(this.state.make, this.state.startYear, this.state.endYear);  
          };
    };

    handleChange = (event) => {
      event.preventDefault();
      let make = event.target.value;
      this.setState({make});
    }

    handleSelectStart = (event) => {
        event.preventDefault();
        console.log("Selected start :", event.target.value);
        this.setState( {startYear : event.target.value });
        };
  
    handleSelectEnd = (event) => {
            event.preventDefault();
            console.log("Selected end :", event.target.value);
            this.setState( {endYear : event.target.value });
        };

    render(){
      let message = "Enter the new make";
      return(
            <Modal
            show={this.props.showMake}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {message}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col sm={6}>
                    <form onSubmit={this.handleSubmit}>
                    <div className='group align-center'>
                        <label htmlFor='newMake'>Make : </label>
                            <input type='character' autoFocus name='newMake' 
                                value={this.state.newMake} 
                                onChange={this.handleChange}
                            /> 
                      </div>
                      <div className='group align-center'>
                        <label htmlFor='startYear'>Start Year : </label>
                            <Select 
                            name="startYear"
                            label=" "
                            options={this.state.years}
                            value={this.state.startYear}
                            onChange={this.handleSelectStart}
                            />
                    </div>
                    <div className='group align-center'>
                        <label htmlFor='endYear'>End Year : </label>
                            <Select 
                            name="endYear"
                            label=" "
                            options={this.state.years}
                            value={this.state.endYear}
                            onChange={this.handleSelectEnd}
                            />
                    </div>
                    <Button type="submit" className="buttons" onClick={this.handleSubmit}>Add Make</Button>
                    </form>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        );
    };
};