import React from "react";
import { SignIn } from "aws-amplify-react";
import config from "./aws-exports";
import { Login } from "./components/views/auth/login";
import App from "./App";
import { Authenticator } from "aws-amplify-react";

class AppWithAuth extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Authenticator hide={[SignIn]} amplifyConfig={config}>
        <Login />
        <App />
      </Authenticator>
    );
  }
}

export default AppWithAuth;
