import React, { Component } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';


export class SelectCustomerVehicle extends Component {

  state = {
    selectedVehicle : "X"
  }


  handleSelect = async () => {
    this.props.onSelect(this.props.vehicles[this.state.selectedVehicle])
  };

// In this modal, we'll display the list of vehicles we have already done work for 
// the user can pick one and it will populate the dropdowns or cancel and ad a new one

    render(){
//      console.log("Vehicles we have :", this.props.vehicles);


    const columns = [{
        dataField: 'Year',
        text:"Year"},{
        dataField: 'Make',
        text:"Make"},{
        dataField: 'Model',
        text:"Model"},{
        dataField: 'Trim',
        text:"Trim"},{
        dataField: 'body',
        text:"Style"}
    ];

      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor : 'grey',
        onSelect: (e, row, rowIndex) => {
          this.setState({selectedVehicle : rowIndex});
          console.log ("Row selected : ", this.state.selectedVehicle);
          //            this.props.onSelect(row);
        }
      };

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          this.setState({selectedVehicle : rowIndex});
          console.log ("Row selected : ", this.state.selectedVehicle);
        }};

      return(
            <Modal
            show={this.props.showList}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Select one of customer's previous vehicles or Add a new one 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
              <BootstrapTable
                  keyField='VIN'
                  data={this.props.vehicles}
                  columns={columns}
                  selectRow={selectRow} 
                  rowEvents={ rowEvents }/>
              </div>
            {this.state.selectedVehicle !== "X" && <button type="button" onClick={() => this.handleSelect()} className="btn btn-success m-2 buttons">Select</button>}
            <button type="button" onClick={() => this.props.onHide()} className="btn btn-danger m-2 buttons">Enter New One</button>

            </Modal.Body>
          </Modal>
        );
    };
};