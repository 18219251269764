import axios from "axios";
import { toast } from "react-toastify";
import { Auth } from 'aws-amplify';

require('dotenv').config();

//console.log (process.env.REACT_APP_API_URL);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json',
  'Cache-Control': 'no-cache', 
  'Access-Control-Allow-Headers': 'https://jobvious.app',
  'Access-Control-Allow-Origin': 'https://jobvious.app' }
});

instance.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    toast.error("An unexpected error occurred.");
  }
  console.log("This is the error code :", error.response.status);
  return Promise.reject(error);
});

instance.interceptors.request.use(async config => 
  {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.accessToken.jwtToken;

  config.headers.Authorization = token;

  return config;

}, (error) => {
  console.log("This is the error code :", error.response.status);

  return Promise.reject(error);
});

export class HTTPService {
  // eslint-disable-next-line no-useless-constructor
  constructor () {}

  get (url, parameters = {}) {
    if (parameters) {
      return instance.get(url, {params: parameters})
    } else {
      return instance.get(url)
    }
  }

  post (url, payload) {
    return instance.post(url, payload)
  }

  put (url, payload) {
    return instance.put(url, payload)
  }

  patch (url, payload) {
    return instance.patch(url, payload)
  }

  delete (url) {
    return instance.delete(url)
  }
};
