import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import './assets/css/index.css';

import { Auth } from 'aws-amplify';
import AppWithAuth from './AppWithAuth';
import awsmobile from './aws-exports';
Auth.configure(awsmobile);

ReactDOM.render(< AppWithAuth />, document.getElementById('root'));

// serviceWorker.unregister();
serviceWorker.register();
