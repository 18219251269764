import React, { Component } from 'react';
import Select from './../../../components/common/select';
import { HTTPService } from "./../../../services/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import moment from 'moment';
import _ from 'lodash';


const API = new HTTPService();


class Financials extends Component {
    state = { 
        typeId : this.props.typeId,
        transactions : [],
        notPaid : [],
        offers : [],
        requests : [],
        estimates : [],
        services : [],
        selectedTime : "This Month",
        owed : 0,
        pending : 0,
        earned : 0
    };
  

     async componentDidMount() {
        
     // get all the financials events for the installer
        
            let financialsRequest = `/installerPayments/${this.state.typeId}`;        
            const  { data : transactions }  = await API.get(financialsRequest);
            this.setState({ transactions});
            let notPaid = transactions.filter(item => item.payment_state === "owed" || item.payment_state === "invoiced");
            this.setState( {notPaid} );

            // get all the offers for the installer
            let offersRequest = `/requestOffered/installer/${this.state.typeId}`;        
            const  { data : offers }  = await API.get(offersRequest);
//            let offers = allOffers.filter(item => item.date_updated != null);
            this.setState({ offers});
        };
  
        handleSelect = (event) => {
            const {value} = event.currentTarget;
//            console.log ("Select Event :" , value);
            this.setState( {selectedTime : value });
        };

    render() { 
        
        const options = ["This Year","This Month"];

        const divHeight = {
            background : "#ffffff"
        }
        const divHeight1 = {
            height:"70px",
            background : "#ffffff"
        }
        const overStyle = { 
            backgroundColor : '#FA626D'
        }

        const estStyle = { 
            backgroundColor : '#A2E6C3'
        }

        const reqStyle = { 
            backgroundColor : '#FFD9AC'
        }
        const jobStyle = { 
                backgroundColor : '#98CBE5'
        }
    
        // Build the counts for display based on the selected date
        var workingArray = [];
        var startDate = "";

        console.log("offers :", this.state.offers);

        if (this.state.offers.length > 0) {
            if (this.state.selectedTime === "This Year"){
            // filter offers to only include this year
                startDate = new moment().format("YYYY");
                workingArray = this.state.offers.filter(item => item.date_updated !== null && item.date_updated.substring(0,4) === startDate);
            };
            if (this.state.selectedTime === "This Month"){
            // filter offers to only include this month
                startDate = new moment().format("YYYY-MM");
                workingArray = this.state.offers.filter(item => item.date_updated !== null && item.date_updated.substring(0,7) === startDate);

            };
        };
        let dayOfWeek = new moment().weekday();
    //        console.log("Today :", new moment())
    //        console.log("Day of week :",dayOfWeek)
        let dayAdd = 1;
        if (dayOfWeek === 5){
            dayAdd = 3
        };
        if (dayOfWeek === 6){
            dayAdd = 2
        };
        let endOfMonth = new moment().add(dayAdd,"days").format("ddd, MMM Do, YYYY");

        // working Array contains all the jobs in the time period

        let installerWork = workingArray.filter(item => item.requestType === "installer"); 
        let dealerWork = workingArray.filter(item => item.requestType === "dealer");

        // Estimates
        let created = installerWork.length;
        let notClosed = installerWork.filter(item => item.Current_Status === "new" || item.Current_Status === "deleted");
        let closed = created - notClosed.length;
        // Requests
        let offered = dealerWork.length;
        let onlyOffered = dealerWork.filter(item => item.Current_Status === "offered" || item.Current_Status === "expired");
        let accepted = offered - onlyOffered.length;
        // Jobs
        let jobs = workingArray.filter(item => item.current_state === "scheduled" || item.current_state === "started" || item.current_state === "received" ||
                                               item.current_state === "finished" || item.current_state === "reviewed" || item.current_state === "collected");
        let won = jobs.length;
        let done = workingArray.filter(item => item.current_state === "finished"|| item.current_state === "collected" || item.current_state === "reviewed");
        let completed = done.length;
        let earned = 0;
        let totalTax = 0;
        console.log("Array of jobs :", jobs)
        console.log("Working array of completed work :", done);
        if (done.length > 0) {
            // incorporate fees & taxes here..
            let amountsEarned = done.map(item => (item.request_total - item.request_fee));
            console.log("amounts earned :", amountsEarned);
            earned = amountsEarned.reduce(function (total, currentValue){ return total + currentValue});
            console.log("Total amount earned :", earned);
            let taxAmounts = done.map(item => (item.request_taxes));
            totalTax = taxAmounts.reduce(function (total, currentValue){ return total + currentValue});
        };

        const columns = [{
            dataField: 'vehicle',
            text: "Vehicle",
            formatter : (cell, row) => (<a href={row.link} target="_blank"> {cell} </a>)
        },{
            dataField: 'invoiceDate',
            text: "Invoiced"
          },  {
            dataField: 'currentState',
            text: "State"
          },{
            dataField: 'service',
            text: "Work"
          }, {
            dataField: 'amount',
            text: "Amount"
          }, {
            dataField: 'taxes',
            text: "Tax"
          }, {
            dataField: 'fee',
            text: "Fee"
          }, {
            dataField: 'earned',
            text: "Earned"
          }];
          // map items from owed into requests
          // for every item in the notPaid array, get the details from the offers array and display

          const rowStyle2 = (row, rowIndex) => {
            let range = 4;
            if (this.state.selectedTime === "This Month")
                range = 7;
            if (row.invoiceDate.substring(0,range) !== startDate) {
                return overStyle
            } else {
               if (row.currentState === "In Shop") {
                   return jobStyle
               } else {
               if (row.requestType === "Retail") {
                    return estStyle;
                } else {
                    return reqStyle;
                }};      
            };
        };


        var displayArray = [];
        console.log("Not Paid Items : ", this.state.notPaid);
        console.log("Offers : ", this.state.offers);
        console.log("Working Array :", workingArray)


        let periodInvoiced = 0;
        let periodPaid = 0;
        let periodTaxes = 0;
        let periodFees = 0;
        let periodAmount = 0;
        let periodUnpaid = 0;
        
          for (let i=0;  i < jobs.length; i++){
            let displayObject = {
                invoiceDate : "",
                currentState : "",
                link : "",
                requestId : "",
                requestType : "",
                service : "",
                vehicle : "",
                amount : "",
                fee : "",
                taxes : "",
                earned : ""
            };
            let found = this.state.transactions.findIndex(item => item.request_id === jobs[i].Request_id);
//            console.log (`Index found for ${jobs[i].Request_id} is ${found}`);
            if (found > -1) {
                displayObject.invoiceDate = moment(this.state.transactions[found].invoice_date).format("YYYY-MM-DD");
                displayObject.requestId = jobs[i].Request_id;
                displayObject.requestType = "Retail"
                if (this.state.transactions[found].request_type === "dealer") {
                    displayObject.requestType = "Dealer"
                }
                displayObject.service = jobs[i].Service_Requested;
                displayObject.currentState = _.capitalize(this.state.transactions[found].payment_state);
                displayObject.link = this.state.transactions[found].hosted_invoice_url;
                displayObject.vehicle = jobs[i].Year + " " + jobs[i].Make + " " + jobs[i].Model ;
                displayObject.amount = jobs[i].request_total.toFixed(2);
                displayObject.fee = jobs[i].request_fee.toFixed(2);
                displayObject.taxes = jobs[i].request_taxes.toFixed(2);
                displayObject.earned = (jobs[i].request_total - jobs[i].request_fee).toFixed(2);
                if (displayObject.currentState === "Draft") {
                    displayObject.currentState = "In Shop";
                    displayObject.invoiceDate = new moment().format("YYYY-MM-DD")
                } else {
                    periodInvoiced = periodInvoiced + (jobs[i].request_total + jobs[i].request_taxes);
                    periodTaxes = periodTaxes + (jobs[i].request_taxes);
                    periodFees = periodFees + jobs[i].request_fee;
                    periodAmount = periodAmount + jobs[i].request_total;
                    if (displayObject.currentState === "Paid"){
                        periodPaid = periodPaid + (jobs[i].request_total + jobs[i].request_taxes - jobs[i].request_fee);
                    }
                    if (displayObject.currentState === "Invoiced"){
                        periodUnpaid = periodUnpaid + (jobs[i].request_total + jobs[i].request_taxes - jobs[i].request_fee);
                    }
                }
                displayArray.push(displayObject);
            };
          }
          // need to also include the transactions outside the window that are not yet paid
          let unpaidTransactions = this.state.transactions.filter(item => item.payment_state === "invoiced" || item.payment_state === "owed");
 //         console.log("unpaid transactions to process :", unpaidTransactions);
 //         console.log("offers :", this.state.offers)
          for (let i=0;  i < unpaidTransactions.length; i++){
            let displayObject = {
                invoiceDate : "",
                currentState : "",
                link : "",
                requestId : "",
                requestType : "",
                service : "",
                vehicle : "",
                amount : "",
                fee : "",
                taxes : "",
                earned : ""
            };
            let found = this.state.offers.findIndex(item => item.Request_id === unpaidTransactions[i].request_id);
  //          console.log (`Index found for ${unpaidTransactions[i].request_id} in Offers is ${found}`);
            let exists = displayArray.findIndex(item => item.requestId === unpaidTransactions[i].request_id);
  //          console.log (`Index found for ${unpaidTransactions[i].request_id} in DisplayArray is ${exists}`);
            if (exists === -1) { 
                if (found > -1) {
                    displayObject.invoiceDate = moment(unpaidTransactions[i].invoice_date).format("YYYY-MM-DD");
                    displayObject.requestId = unpaidTransactions[i].request_id;
                    displayObject.requestType = "Retail"
                    if (unpaidTransactions[i].request_type === "dealer") {
                        displayObject.requestType = "Dealer"
                    }
                    displayObject.service = this.state.offers[found].Service_Requested;
                    displayObject.currentState = _.capitalize(unpaidTransactions[i].payment_state);
                    displayObject.link = unpaidTransactions[i].hosted_invoice_url;
                    displayObject.vehicle = this.state.offers[found].Year + " " + this.state.offers[found].Make + " " + this.state.offers[found].Model ;
                    displayObject.amount = this.state.offers[found].request_total.toFixed(2);
                    displayObject.fee = this.state.offers[found].request_fee.toFixed(2);
                    displayObject.taxes = this.state.offers[found].request_taxes.toFixed(2);
                    displayObject.earned = (this.state.offers[found].request_total - this.state.offers[found].request_fee).toFixed(2);
                    if (displayObject.currentState === "Draft") {
                        displayObject.currentState = "In Shop";
                        displayObject.invoiceDate = new moment().format("YYYY-MM-DD")
                    } else {
                        periodUnpaid = periodUnpaid + this.state.offers[found].request_total + this.state.offers[found].request_taxes;
                    }
                    displayArray.push(displayObject);
                };
            };
          }
          displayArray.sort((a,b) => ((b.invoiceDate > a.invoiceDate) || (b.invoiceDate === a.invoiceDate && a.currentState > b.currentState)));

          console.log("Period Invoiced :", periodInvoiced);
          console.log("Period Paid :", periodPaid);
          let due = earned + totalTax;

          const requests = displayArray;
          
        return ( 
            <React.Fragment>
            <div className="container-flex m-3">
                <div className="row text-center">
                    <div className="col-3 text-center">
                        <Select 
                            name="time"
                            label=" "
                            options={options}
                            value={this.state.selectedTime}
                            onChange={this.handleSelect}
                            />
                    </div>
                    <div className="col text-right">
                        <p>Next scheduled payout : {endOfMonth}</p>
                    </div>

                </div>
                <div className="row" >
                    <div className="col text-center">
                        <div className="card-deck">
                            <div className="card shadow w-25 corners" style={estStyle} >
                                <div className="card-text">
                                    <h5>Estimates</h5>
                                    <div className="row" >
                                        <div className="col">Created : {created}</div>
                                    </div>
                                    <div className="row" >
                                        <div className="col">Closed : {closed}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners" style={reqStyle} >
                                <div className="card-text">
                                    <h5>Requests</h5>
                                    <div className="row" >
                                        <div className="col">Offered : {offered}</div>
                                    </div>
                                    <div className="row" >
                                        <div className="col">Accepted : {accepted}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners" style={jobStyle}>
                                <div className="card-text">
                                    <h5>Jobs</h5>
                                    <div className="row" >
                                        <div className="col">Booked : {won}</div>
                                    </div>
                                    <div className="row" >
                                        <div className="col">Completed : {completed}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow w-25 corners">
                                <div className="card-text">
                                    <h5>$ Due</h5>
                                    <div className="row" >
                                        <div className="col">Earned + Taxes: ${due.toFixed(2)}</div>
                                    </div>
                                    <div className="row" >
                                        <div className="col">Paid Out : ${periodPaid.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-flex m-3 " style={divHeight1}>
            <div className="row" >
                    <div className="col text-center">
                        <h6>Invoice (Amnt + Tax)</h6>
                    </div>
                    <div className="col text-center">
                        <h6>Amount</h6>
                    </div>
                    <div className="col text-center">
                        <h6>Taxes</h6>
                    </div>
                    <div className="col text-center">
                        <h6>Fees</h6>
                    </div>
                    <div className="col text-center">
                        <h6>Earned (Amnt - Fee)</h6>
                    </div>
                    <div className="col text-center">
                        <h6>Unpaid</h6>
                    </div>
                </div>
                <div className="row" >
                    <div className="col text-center">
                        <h6>${periodInvoiced.toFixed(2)}</h6>
                    </div>
                    <div className="col text-center">
                        <h6>${periodAmount.toFixed(2)}</h6>
                    </div>
                    <div className="col text-center">
                        <h6>${periodTaxes.toFixed(2)}</h6>
                    </div>
                    <div className="col text-center">
                        <h6>${periodFees.toFixed(2)}</h6>
                    </div>
                    <div className="col text-center">
                        <h6>${earned.toFixed(2)}</h6>
                    </div>
                    <div className="col text-center">
                        <h6>${periodUnpaid.toFixed(2)}</h6>
                    </div>
                </div>
                </div>
                <div className="container-flex m-3 " style={divHeight}>
                <div className="row">
                   <div className="col text-center">
                    <BootstrapTable
                    keyField='requestId'
                    data={requests}
                    columns={columns} 
                    rowStyle={ rowStyle2 }/>
                    </div>
                </div>
            </div>
            </React.Fragment>
      
         );
    }
}
 
export default Financials;