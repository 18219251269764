import React, { Component, useState } from 'react';
import Select from './../common/select';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';

export class AddService extends Component {

    state = {
        catalog : "",
        catalogs : ["RETAIL", "WHOLE"],
        category : "",
        description : "",
        vehicleType : "auto",
        priceL : 0,
        serviceL : 0,
        priceM : 0,
        serviceM : 0,
        priceS : 0,
        serviceS : 0,
        duration : 0
    };

    handleChange = (evt) => {
        evt.preventDefault();
          const name = evt.target.name;
          const value = evt.target.value;
          this.setState({
            ...this.state,
            [name]: value
          })
      }

      handleSelect = (event) => {
        const { value } = event.target;      
        console.log("Selected catalog :", value);
        this.setState( {catalog : value });
      };
  
    handleSubmit = (event) => {
        event.preventDefault();
        console.log ("New data updated");
//        this.setState({ catalog, category, description, priceL, priceM, priceS, duration});
        if (this.props.action === "add"){
          this.props.onAdd(this.state.catalog, this.state.category, this.state.description, this.state.duration, this.state.priceL, this.state.priceM, this.state.priceS);
        } else {
          this.props.onUpdate(this.state.catalog, this.state.category, this.state.description, this.state.duration, this.state.priceL, this.state.priceM, this.state.priceS);
        }
    };

    render(){


        return(
          <div>
            <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Enter the service information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col sm={6}>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="NewService">
                      <Form.Label>Catalog</Form.Label>
                      <Select 
                            name="catalog"
                            label=" "
                            options={this.state.catalogs}
                            defaultValue={this.props.catalog}
                            onChange={this.handleChange}
                            />                     
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                        type="text"
                        name="category"
                        required
                        defaultValue={this.props.category}
                        onChange={this.handleChange}
                        placeholder="The category name"
                        />
                      <Form.Label>Description</Form.Label>
                        <Form.Control
                        type="text"
                        name="description"
                        required
                        onChange={this.handleChange}
                        defaultValue={this.props.description}
                        placeholder="Service Description"
                        />
                      <Form.Label>Large Price</Form.Label>
                        <Form.Control
                        type="number"
                        name="priceL"
                        required
                        onChange={this.handleChange}
                        defaultValue={this.props.priceL}
                        placeholder="Large Vehicle"
                        />
                      <Form.Label>Medium Price</Form.Label>
                        <Form.Control
                        type="number"
                        name="priceM"
                        required
                        defaultValue={this.props.priceM}
                        onChange={this.handleChange}
                        placeholder="Medium Vehicle"
                        />
                      <Form.Label>Small Price</Form.Label>
                        <Form.Control
                        type="number"
                        name="priceS"
                        required
                        defaultValue={this.props.priceS}
                        onChange={this.handleChange}
                        placeholder="Small Vehicle "
                        />
                      <Form.Label>Duration</Form.Label>
                        <Form.Control
                        type="number"
                        name="duration"
                        required
                        defaultValue={this.props.duration}
                        onChange={this.handleChange}
                        placeholder="How long will it take (in days)"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Button variant="primary" type="submit" className="buttons">
                          Save Details
                        </Button>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          </div>
        );
    };
};