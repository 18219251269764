import React, { Component } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';


export class SelectCustomerJob extends Component {

  state = {
    selectedJob : "X"
  }

  handleSelect = async () => {
    this.props.onSelect(this.props.jobs[this.state.selectedJob])
  };

// In this modal, we'll display the list of jobs for the selected vehicle we have already done work for 
// the user can pick one and it will populate the list or cancel and add new ones
  
    render(){
      console.log("Jobs we have :", this.props.jobs);

      let displayJobs = this.props.jobs;
      for (let index = 0; index < displayJobs.length; index++) {
        let conv = moment(displayJobs[index].created_date).format("MMM Do, YYYY");
        displayJobs[index].createDateString = conv;
      }
      
    const columns = [{
      dataField: 'createDateString',
      text:"When"},{
      dataField: 'service_requested',
        text:"Service"},{
        dataField: 'Request_total',
        text:"Amount"}
    ];


      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor : 'grey',      
        onSelect: (e, row, rowIndex) => {
          this.setState({selectedJob : rowIndex});
//          console.log ("Row selected : ", this.state.selectedJob);
          //            this.props.onSelect(row);
        }
      };

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          this.setState({selectedJob : rowIndex});
 //         console.log ("Row selected : ", this.state.selectedJob);
 //            this.props.onSelect(row);
        }};

        let message = "Jobs completed for this customer"
        if (this.props.mode === "select") {
          message = "Select one of customer's previous jobs or Add a new one"
        }

      return(
            <Modal
            show={this.props.showJobList}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {message}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
              <BootstrapTable
                  keyField='request_id'
                  data={displayJobs}
                  columns={columns}
                  selectRow={selectRow}
                  rowEvents={rowEvents} />
              </div>
            {this.props.mode === "select" && this.state.selectedJob !== "X" && <button type="button" onClick={() => this.handleSelect()} className="btn btn-success m-2 buttons">Select</button>}
            {this.props.mode === "select" && <button type="button" onClick={() => this.props.onHide()} className="btn btn-danger m-2 buttons">Enter New One</button>}
            {this.props.mode === "view" && <button type="button" onClick={() => this.props.onHide()} className="btn btn-danger m-2 buttons">Cancel</button>}

            </Modal.Body>
          </Modal>
        );
    };
};