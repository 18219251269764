// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:faa6f3d4-3e06-49ce-b826-aae656550dbf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_T1uhNWA5f",
    "aws_user_pools_web_client_id": "74hq21lqpd4utojmc0kttl21nl",
    "oauth": {}
};


export default awsmobile;
