import React from 'react';
import _ from "lodash";
import moment from 'moment';
import 'moment-timezone';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import DatePicker from 'react-datepicker';
import {CheckOut} from '../../partial/checkOut'
import Select from '../../common/select';
import "react-datepicker/dist/react-datepicker.css";
import StarRatingComponent from 'react-star-rating-component';
import Pagination from 'react-js-pagination';
import { convertToUTC, convertFromUTC } from '../../../util/convert_timezone';


const requestimateState = (props) => {

  const { userType, typeId, installer, request, services, notes, displayedList, offers, history, invoice, calendarDays, excludeDays, startDate, paymentIntentId, onStartDateChange, 
    template, onTemplateChange, materials, onMaterialsChange, vin, vehicleEntered, vinGood, onVINChange,
    onDelete, onSelectOffer, selectedInstaller, onAccept, onAcceptEstimate, onCompleteEstimate, onReject, onAssign, onReceive, onReschedule, onStart, onFinish, 
    reviewScore, onReviewScoreChange, onSettle, onResend, onFinalize, onReview, selectedPay, onPaymentChange, onPayment, onDeliver, onPageChange, activePage, showSwipe, closeSwipe, loading } = props;

    function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

var payList = ["Check/Cash", "Pay Online", "Swipe Card"];

if (installer.stripe_location === "") 
payList = ["Check/Cash", "Pay Online", "Settle"];

  function stripeOutside(url) {
    console.log(invoice);
    window.open(url);
  };

  // if nothing selected, selected item isn't in the displayed list, we are done
  if (isEmpty(props.request)) {
    return null;
  } else {

    let check = [];
    check = displayedList.filter(item => (item.requestId === request.requestId));

    if (check.length === 0) {
      return null;
    } else {

    // if something selected, but selected item isn't in the displayed list, we are done
   
   
  /* This section determines the offers to show to the user. */
  /* if the user is the installer, they only see their own offer */
  /* if the user is the dealer, they see all the answered offer */

  let displayOffers = offers;
  let selectedOffer = [];


  var noteText = "None";
  if (notes.length > 0) {
    if (notes[0].note_text.length > 1 )
    noteText = notes[0].note_text;
  }

  
  for (let index = 0; index < displayOffers.length; index++) {
    let conv = convertToUTC(displayOffers[index].startDate).format("ddd, MMM Do, YYYY");
    displayOffers[index].startDateString = conv;
    displayOffers[index].installerName = offers[index].installerName ;
    // console.log ("Date conv :", conv);
  }
  
  
  if (props.userType === "installer") {
    selectedOffer = offers.filter(offer => (offer.installerId === parseInt(typeId)))
  }
  if (props.userType === "dealer") {
    displayOffers = offers.filter(offer => (offer.currentStatus === "accepted"))
  }

  // This section sets up the initial values for dates & Services
  
      const thisService = activePage - 1;

      let dueDate = new Date(request.dueDate);
      if (userType === "installer" && request.requestType === "installer") {
        // add 30 days to proposed due date 
        let calcDate = new moment(request.dueDate).add(30, "days");
        dueDate = new Date(calcDate);
      }
  
      const minDate = new Date(request.availableDate);
      
      let sDate = new Date(startDate);
      if (sDate < minDate) {
        sDate = minDate
      };
      let jobDuration = parseInt(request.requestDuration);
      let latestStartDate = new moment(request.dueDate).subtract(jobDuration, "days");
      let maxDate = new Date(latestStartDate);
      
//      console.log("Max Date Allowed: ", maxDate);
//      console.log("Duration of job :", request.requestDuration);
      // console.log("Min Date : ", minDate);
       console.log("Calendar of events ", calendarDays);
  
        var thisJob = calendarDays.filter(day => day.requestId === request.requestId);
        _.orderBy(thisJob,['workDate'],['asc']);
        var serviceCount = thisJob.length;

       console.log("This job is :", thisJob);
 //       console.log("Selected Installer :", installer);
 //       console.log("Exclude Days :", excludeDays);
 //       console.log("Work to do :", (installer.capacity - thisJob[0].duration))

    // set up dates to exclude based on the calendar
    let exclude = [];
    if (thisJob.length > 0) {
    for (let b=0; b< excludeDays.length; b++){
      // this should be the capacity of the installer vs the size of job
      if (excludeDays[b].y > (installer.capacity - thisJob[0].duration)){
//        console.log ("Exclude :", new Date(moment(excludeDays[b].exDate).format("MMMM D, YYYY")));
        exclude.push(new Date(moment(excludeDays[b].exDate).format("MMMM D, YYYY")));
      }
    }
  }
//    console.log ("Exclude these :", exclude)
    let thisJobDates = [];
    for (let c=0; c< thisJob.length; c++){
        thisJobDates.push(new Date(moment(thisJob[c].workDate).format("MMMM D, YYYY")))
    }
    
    console.log("RequestimateState : Payment Intent ", paymentIntentId);
    console.log("RequestimateState : Invoice ", invoice);
//************************************************************************
//***************************  Dealer Pages ******************************
//************************************************************************

// **********************************
// New Request display for Dealer user
// **********************************
if (request.currentState === "new" && props.userType === "dealer") 
{
  const columns = [{
    dataField: 'Service_Category',
    text: "Category"
}, {
    dataField: 'Description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = services;
console.log ("RequestimateState : services :", services);
/* for (let i=0; i < services_.length; i++) {
  services_[i].service_amount = "$" + services[i].service_amount.toFixed(2);
} 
*/ 

console.log ("request state , showSwipe", showSwipe);

return (
    <React.Fragment>
        <div className="card text-center">
            <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='Service_Id'
                  data={services_}
                  columns={columns} />
              </div>
              <div className="text-center">
                <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>
              </div>
            </div>
          </div>

        </React.Fragment>
      );
};

      //*********************************
      // For Answered request for Dealer 
      //*********************************

      if (request.currentState === "answered" && props.userType === "dealer") 
      {
        const selectRow = {
          mode: 'radio',
          clickToSelect: true,
          bgColor : 'grey',
          onSelect: (e, row, rowIndex) => {
            onSelectOffer(row);
          }
        };
      
        const columns = [{
          dataField: 'installerName',
          text:"Installer"},{
          dataField: 'startDateString',
          text:"Date to Start"}
        ];
        const rowEvents = {
          onClick: (e, row, rowIndex) => {
            onSelectOffer(row);
          }};

        return (
        
        <React.Fragment>
          <div className="card text-center">
            <div className="card-body">
              <h5 className="card-title">Pick the installer to do the work</h5>
              <div className="table-responsive">
                <BootstrapTable
                  keyField='installerId'
                  data={displayOffers}
                  columns={columns}
                  selectRow={selectRow} 
                  rowEvents={ rowEvents }/>
              </div>

              <div className="text-center">
                <button type="button" disabled={!selectedInstaller} onClick={() => onAssign(request, selectedInstaller)} className="btn btn-success m-2 buttons">ASSIGN</button>
                <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>
              </div>
            </div>
          </div>
        </React.Fragment>
        )
        };

// **********************************
// For Scheduled job for Dealer
// for Received state for Dealer 
// For In progress job for Dealer
// For finsihed job for Dealer
// Show the current state of services in the job
// **********************************
if ((request.currentState === "scheduled" || request.currentState === "started" || request.currentState === "received" || 
request.currentState === "finished") && props.userType === "dealer") {
  // set up the table to display the services and their state
const columns = [{
  dataField: 'Service_Category',
  text: "Category"
}, {
  dataField: 'Description',
  text: "Service"
}, {
  dataField: 'Status',
  text: "Status"
}];
let services_ = [];
  
// set up the array for display of history
// for each service, find the latest entry in the history array 

for (let index=0; index < services.length; index++){
  let aService = {
    Service_id : 0,
    Service_Category : "",
    Description : "",
    Status : "Scheduled"
  }
  aService.Service_id = services[index].Service_id;
  aService.Service_Category = services[index].Service_Category;
  aService.Description = services[index].Description;
  let serviceHistory = history.filter(item => (item.requestService === aService.Service_id));
  // console.log("service history :", serviceHistory)
  if (serviceHistory.length > 0){
    switch (serviceHistory[serviceHistory.length - 1].requestStatus) {
      case "start" : aService.Status = "In Progress"; break;
      case "finish" : aService.Status = "Completed"; break;
      default : aService.Status = "Sched"; break;
    };
  }
  // console.log("aService", aService)
  services_.push(aService);
}

return (
        <React.Fragment>
          <div className="card text-center">

            <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='Service_id'
                  data={services_}
                  columns={columns} />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
};

// **********************************
// For finished job for Dealer
// **********************************
if (request.currentState === "collected" && props.userType === "dealer") return (
  <React.Fragment>
    <div className="card text-center">
      <div className="card-body">
        <h5 className="card-title">Rate the installer work</h5>
        <div className="col-lg-12">
          <StarRatingComponent
            name="rate1"
            starCount={5}
            value={reviewScore}
            onStarClick={onReviewScoreChange}
          />
        </div>
        <button type="button" onClick={() => onReview(request)} disabled={!reviewScore} className="btn btn-success m-2 buttons">REVIEW</button>
      </div>
    </div>
  </React.Fragment>
);




//************************************************************************
//***************************  Installer Pages ***************************
//************************************************************************

// ******************************************
// New Request display for Installer Estimate
// ******************************************
if (request.currentState === "new" && props.userType === "installer" && request.requestType === "installer") 
{
  const columns = [{
    dataField: 'Service_Category',
    text: "Category"
}, {
    dataField: 'Description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = services;
for (let i=0; i < services_.length; i++) {
  let amount = services[i].service_amount - services_[i].service_discount;
  services_[i].service_amount = "$" + amount.toFixed(2);
}  
console.log("Start Date :", startDate);
return (
    <React.Fragment>
        <div className="card text-center">
            <div className="card-body">
              <div className="table-responsive">
                <BootstrapTable
                  keyField='Service_id'
                  data={services_}
                  columns={columns} />
              </div>
            </div>
            <div className="mt-4 mb-4">
              <form className="form-row justify-content-md-center">
                <div className="form-group col-lg-2 text-center">
                  <label className="bold">Scheduled Start Date</label>
                </div>
                <div className="form-group col-lg-4">
                  <DatePicker
                    name="startDate"
                    label="Pick Start Date"
                    value={sDate}
                    selected={sDate}
                    minDate={minDate}
                    excludeDates={exclude}
                    highlightDates={thisJobDates}                                  
                    onSelect={onStartDateChange}/>
                </div>
              </form>

            <button type="button" onClick={() => onCompleteEstimate(request, startDate)} className="btn btn-success m-2 buttons">INVOICE - WORK DONE</button>
            <button type="button" onClick={() => onAcceptEstimate(request, startDate)} className="btn btn-success m-2 buttons">BOOK</button>
            <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons">CANCEL</button>
            </div>
          </div>
        </React.Fragment>
      );
};

// ***********************************************************
// New request display for Installer user for a dealer request 
// ***********************************************************
if (request.currentState === "new" && props.userType === "installer" && request.requestType === "dealer") 
{
  const columns = [{
    dataField: 'Service_Category',
    text: "Category"
}, {
    dataField: 'Description',
    text: "Service"
}, {
    dataField: 'service_amount',
    text: "Amount"
}, {
    dataField: 'service_duration',
    text: "Duration"
}];

let services_ = services;
for (let i=0; i < services_.length; i++) {
  services_[i].service_amount = "$" + services[i].service_amount.toFixed(2);
}  

        return(
          <React.Fragment>
        <div className="card text-center">
          <div className="card-body">
          <div className="table-responsive">
                <BootstrapTable
                  keyField='Service_Id'
                  data={services_}
                  columns={columns} />
              </div>
            <div className="mt-4 mb-4">
              <form className="form-row justify-content-md-center">
                <div className="form-group col-lg-2 text-center">
                  <label className="bold">Pick Start Date</label>
                </div>
                <div className="form-group col-lg-4">
                  <DatePicker
                    name="startDate"
                    label="Pick Start Date"
                    value={sDate}
                    selected={sDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={exclude}
                    highlightDates={thisJobDates}
                    onSelect={onStartDateChange}
                  />
                </div>
              </form>
            </div>
            <button type="button" onClick={() => onAccept(request, startDate)} className="btn btn-success m-2 buttons">ACCEPT</button>
            <button type="button" onClick={() => onReject(request)} className="btn btn-danger m-2 buttons">REJECT</button>
          </div>
        </div>
        </React.Fragment>
        )
      };
// ***********************************************
// Installer receiving car from dealer/customer
// ***********************************************
if ( request.currentState === "scheduled" 
    && props.userType === "installer") {

return (
    <React.Fragment>
          <div className="card text-center">
            <div className="mt-4 mb-4">
              <h6>Scheduled drop off date : {moment(request.availableDate).format("MM/DD/YYYY")}</h6>
                { request.vin.substring(0,2) !== "NV" ? <div>
                    <h6>VIN : {request.vin}</h6>
                </div>
                : <div>
                  <form>
                  <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label className="bold">Enter VIN #</label>
                    </div>
                    <div className="form-group col-lg-4">
                    <input 
                        name="vin"
                        id={vin} 
                        value={vin}
                        onChange={onVINChange}
                        />
                      </div>
                    </div>
                </form>
                </div>}
              {vinGood && request.vin.substring(0,2) === "NV" && <div>
                  <p>{vehicleEntered.ModelYear} {vehicleEntered.Make} {vehicleEntered.Model} </p>
              </div>}
              <button type="button" onClick={() => onReceive(request, vin)} className="button btn-success m-2 buttons" disabled={!vinGood} >CHECK IN</button>
              <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">REJECT</button>
              <button type="button" onClick={() => onReschedule(request)} className="button btn-success m-2 buttons">RESCHEDULE</button>
          </div>
        </div>
    </React.Fragment>
  )};

// ***********************************************
// 3 state pages for a Scheduled job for Installer
// ***********************************************
// *******************************
// Service not started
// *******************************


if ( (request.currentState === "received" || request.currentState === "started" )
  && props.userType === "installer" ) {

    console.log("Not Started :", thisJob, thisService);

    if (thisJob[thisService].workStatus === "booked") {

        return (
          <React.Fragment>
            <div className="card text-center">
            { serviceCount > 1 && <div className="col-4 text-center">   
              <Pagination
              hideFirstLastPages
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={1}
              totalItemsCount={serviceCount}
              pageRangeDisplayed={serviceCount}
              onChange={onPageChange} />
              </div>}
              <div className="card-body">
                <h5 className="card-title">VIN : {request.vin}</h5>

                <div className="mt-4 mb-4">
                <form>
                {thisJob[thisService].dataCollect === "Y" ? 
                  <div>
                    <h6>Enter the template and material used for {thisJob[thisService].category} {thisJob[thisService].description}</h6>
                    <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label htmlFor="template" className="bold">Template #</label>
                    </div>
                    <div className="form-group col-lg-4">
                      <input 
                      name="template"
                      id={template} 
                      value={template}
                      onChange={onTemplateChange}/>
                    </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label htmlFor="material" className="bold">Roll #</label>
                    </div>
                    <div className="form-group col-lg-4">
                      <input 
                      name="material"
                      id={materials} 
                      value={materials}
                      onChange={onMaterialsChange}/>
                    </div>
                    </div>
                  </div> : 
                  <div>
                    <h6>Enter the product used for {thisJob[thisService].category} {thisJob[thisService].description}</h6>
                    <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label htmlFor="material" className="bold">Product</label>
                    </div>
                    <div className="form-group col-lg-4">
                      <input 
                      name="material"
                      id={materials} 
                      value={materials}
                      onChange={onMaterialsChange}/>
                    </div>
                    </div>
                  </div>
                }
                <div className="form-row justify-content-center">
                  <div className="form-group col-lg-12">
                    <button type="button" onClick={() => onStart(request, thisJob, thisJob[thisService].serviceId)} className="btn btn-success m-2 buttons">
                      START
                    </button>
                    {request.requestType=== "installer" && <button type="button" onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE</button>}
                    { noteText !== "None" && 
                    <button type="button" onClick={() => alert(noteText)} className="button btn-info m-2 buttons">NOTES</button>}
                  </div>
                </div>
                </form>
              </div>
            </div>
            </div>
          </React.Fragment>
        );
      }
// **********************************
// Service completed, but more remain
// **********************************

    if (thisJob[thisService].workStatus === "finished") {

      console.log("Finished :", thisJob, thisService);


        return (
          <React.Fragment>
            <div className="card text-center">
            { serviceCount > 1 && <div className="col-4 text-center">   
              <Pagination
              hideFirstLastPages
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={1}
              totalItemsCount={serviceCount}
              pageRangeDisplayed={serviceCount}
              onChange={onPageChange} />
              </div>}
              <div className="card-body text-center">
                <h5 className="card-title">VIN : {request.vin}</h5>
                <div className="mt-4 mb-4">
                <h5 className="card-title">Service Completed</h5>
                <div className="text-center">
                <h5>{thisJob[thisService].category} </h5>
                 <h5>{thisJob[thisService].description}</h5>
                </div>

              </div>
              <h6>Complete the remaining services to finish the job</h6>      
            </div>
            </div>
          </React.Fragment>
        );
      }
    
// *************************************
// For in-progress service for Installer
// *************************************

    if (thisJob[thisService].workStatus === "started") {

      console.log("In Progress :", thisJob, thisService);


        return (
          <React.Fragment>
            <div className="card text-center">
            { serviceCount > 1 && <div className="col-4 text-center">   
              <Pagination
              hideFirstLastPages
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={1}
              totalItemsCount={serviceCount}
              pageRangeDisplayed={serviceCount}
              onChange={onPageChange} />
              </div>}
              <div className="card-body text-center">
                <h5 className="card-title">VIN : {request.vin}</h5>
                <div className="mt-4 mb-4">
                <h5 className="card-title">Service In Progress</h5>
                <div className="text-center">
                 <h5>{thisJob[thisService].category} </h5>
                 <h5>{thisJob[thisService].description}</h5>
                </div>
              </div>
            <button type="button" onClick={() => onFinish(request, thisJob, thisJob[thisService].serviceId )} className="btn btn-success m-2 buttons">FINISH</button>
            </div>

            </div>
            
          </React.Fragment>
        );
      
      }
    }

// *******************************************
// Customer/Dealer picks up car from Installer
// *******************************************
if (request.currentState === "finished" && props.userType === "installer") 

return (
  <React.Fragment>
  <div className="row">
      <div className="col text-center">{invoice.paid ? (<h6>Customer has fully paid</h6>) : (<h6>The customer has been invoiced</h6>) }</div>
    </div>
    <div className="row">
      <div className="col text-center">
            VIN : {request.vin.substring(0,2) === "NV" ? (<b>Not Collected</b>) : (request.vin)}
      </div>
      </div>
    <div className="row">
      <div className="col text-center">
            Invoiced : ${invoice.amount_due/100}
      </div>
      </div>
      <div className="row">
      <div className="col text-center">
            Remaining : ${!invoice.paid ? invoice.amount_remaining/100 : 0}
      </div>
      </div>
    {((invoice.paid && request.requestType === "installer") || request.requestType === "dealer") && 
    <div className="row">
      <div className="col text-center">
      <button type="button" onClick={() => onDeliver(request)} className="button btn-success m-2 buttons">DELIVER</button>
      <button type="button" onClick={() => onResend(invoice.id)} className="button btn-success m-2 buttons">RESEND INVOICE</button>
      </div>
    </div>}
    {(invoice.status === "open" && request.requestType === "installer") &&
        <div>
          <div className="row text-center">
          <div className="col-4"/>
          <div className="col-4 text-center">
          <Select 
            name="paymethod"
            label=" "
            options={payList}
            value={selectedPay}
            onChange={onPaymentChange}
            />
          </div>
        </div>
        <div className="row">
         <div className="col text-center">
               <button className="button btn-success m-2 buttons" onClick={onPayment}> PROCESS PAYMENT </button>
               <button onClick={() => onResend(invoice.id)} className="button btn-success m-2 buttons">RESEND INVOICE</button>
               <button onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE FULL JOB</button>
            </div>
        </div>
        </div>}
        {(invoice.status === "draft" && request.requestType === "installer") &&
        <div>
        <div className="row">
         <div className="col text-center">
               <button onClick={() => onFinalize(invoice.id)} className="button btn-success m-2 buttons">SEND INVOICE</button>
               <button onClick={() => onDelete(request)} className="button btn-danger m-2 buttons">DELETE FULL JOB</button>
            </div>
        </div>
        </div>}
        {selectedPay === "Swipe Card" && showSwipe && <CheckOut
                        show={showSwipe}
                        onClose={closeSwipe}
                        invoice={invoice}
                        request={request}
                        payment={paymentIntentId}
                        installer={installer}
                        onComplete={onSettle}
          />}
    </React.Fragment>
  );
}
}
}

export default requestimateState;
