/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Redirect } from 'react-router-dom'
import Logo from "./../../../assets/Jobvious_logo.png";
import { SignIn } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import { HTTPService } from "../../../services/httpService";
import { isIOS } from 'react-device-detect';

const API = new HTTPService();

export class Login extends SignIn {

    constructor(props) {
        super(props);
        this._validAuthStates = ['signIn', 'signOut'];
    }

    processLogin() {

        super.signIn().then(() =>{

            Auth.currentAuthenticatedUser().then((user) => {

                console.log("user: ");
                console.log(user);
                
                if (user) {

                    const userType = user.signInUserSession.accessToken.payload["cognito:groups"][0];
                    const channel = JSON.parse(localStorage.getItem('X3VhX3NkazoxejB6ckxKNVJLLXNid3N0NG9VbGZROi9wdXNoLXdvcmtlci5qcw==:channel'));

                    const channel_id = channel ? channel.channel_id : null;

                    API.get(`/auth/login/?email=${user.attributes.email}`).then((response) => {

                        const user = response.data;
                        localStorage.setItem('userAPI', JSON.stringify(user));

                        if (userType === 'Dealer') {

                            API.get(`/dealers/${user.Company_id}`).then((response) => {
                                // Company dealer
                                const company = response.data;
                                const companyName = company[0].dealer_name.replace(/ /g,"_").toLowerCase();
                                localStorage.setItem('companyAPI', JSON.stringify(company[0]));
                                localStorage.setItem('named_user_id', JSON.stringify(companyName));

                                // Payload user associate
                                if ( !isIOS ) {
                                    const payload = {
                                        "channel_id": channel_id,
                                        "device_type": "web",
                                        "named_user_id": companyName
                                    }
                                    API.post('/air/user/association', payload);
    
                                    // Payload tag associate
                                    const payloadTags = {
                                        "audience": {
                                            "named_user_id": [companyName]
                                        },
                                        "add": {
                                            "jobvious": ["dealer", companyName]
                                        }
                                    }
                                    API.post('/air/user/association/tags', payloadTags);
                                }
                                window.location = '/requestimates';

                            });

                        } else {

                            API.get(`/installers/${user.Company_id}`).then((response) => {
                            
                                // Company installer
                                const company = response.data;
                                const companyName = company[0].Installer_Name.replace(/ /g,"_").toLowerCase();
                                localStorage.setItem('companyAPI', JSON.stringify(company[0]));
                                localStorage.setItem('named_user_id', JSON.stringify(companyName));

                                // Payload user associate
                                if ( !isIOS ) {
                                    const payload = {
                                        "channel_id": channel_id,
                                        "device_type": "web",
                                        "named_user_id": companyName
                                    }
                                    API.post('/air/user/association', payload);

                                    // Payload tag associate
                                    const payloadTags = {
                                        "audience": {
                                            "named_user_id": [companyName]
                                        },
                                        "add": {
                                            "jobvious": ["installer", companyName]
                                        }
                                    }
                                    API.post('/air/user/association/tags', payloadTags)
                                }
                                window.location = '/requestimates';

                            }).catch(err => {
                                console.log("Err: "+err);
                            });
                        }
                    });

                    // return <Redirect to={ { path: '/requestimates', state: { company: 'teste' } } } />;

                }
            });

        });

    }

    signIn() {
        localStorage.clear();
        const UA = window.UA;
        UA.then(sdk => {
            sdk.register().then(() => {
                this.processLogin();
            }).catch(error => {
                // alert("Your browser does not support Airship notifications.");
                this.processLogin();
            });
        }).catch(error => {
            // alert("Your browser does not support Airship notifications.");
            this.processLogin();
        });
    }

    showComponent(theme) {
        return (
            <div className="App">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content p-5">

                                <div className="row justify-content-md-center">
                                    <div className="card col-lg-4 p5-lg">
                                        <div className="card-body">
                                            <h5 className="card-title text-center mb-5">
                                                <img src={Logo} alt="" />
                                            </h5>
                                            <h6 className="card-subtitle mb-2 text-muted text-center">
                                                Sign in to Jobvious
                                            </h6>
                                            {/* Form */}
                                            <form>
                                                <div className="form-group">
                                                    <label className="font-bold" htmlFor="username" >
                                                        Email
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="username"
                                                        key="username"
                                                        name="username"
                                                        onChange={this.handleInputChange}
                                                        type="text" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-bold" htmlFor="password" >
                                                        Password
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="password"
                                                        key="password"
                                                        name="password"
                                                        onChange={this.handleInputChange}
                                                        type="password"
                                                        autoComplete="off"
                                                    />
                                                    <small className="form-text text-muted mt-2">
                                                        Forgot your password?{" "}
                                                        <a href="#" onClick={() => super.changeState("forgotPassword")} style={{ textDecoration: 'underline' }}>
                                                            Reset Password
                                                        </a>
                                                    </small>
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        className="btn btn-outline-primary btn-block mb-3"
                                                        type="button"
                                                        onClick={() => this.signIn()}
                                                    >
                                                        Lets Go
                                                    </button>
                                                </div>

                                            </form>
                                            {/* End Form */}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col text-center">
                                    <p>&copy; 2019-2024 Jobvious, Inc </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Login;
// export default withRouter(Login);
